*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Figtree";
}

.route__contentWrapper{
  height: fit-content;
  background-image: url("../public/images/abstract\ top\ left.png");
  background-repeat: no-repeat;
  background-color: #1A1A1A;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root{
  min-height: 100vh;
  background-color: #1A1A1A;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 184, 184);
}