.sidebar__wrapper{
    min-height: 100%;
    overflow-y: unset;
}

.component__sidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #1A1A1A;
    transition: all 0.5s ease;
    z-index: 999999;

    @media screen and (max-width: 1040px){
        overflow: hidden;
        position: absolute;
        left: -300px;
        width: 0px;
        top: 0;

        &__mobile{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 10px;

            &__logo{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;

                &>img{
                    width: 32px;
                }

                &>p {
                    color: white;
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }

        &__active{
            width: 200px;
            left: 0;
        }
    }

    &__logo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #F3F3F3F3;
        padding: 20px;

        @media screen and (max-width: 1040px) {
            position: relative;
        }

        &>img {
            width: 40px;
        }

        &>p {
            color: white;
            font-size: 15px;
            font-weight: 600;
        }

        &__closeSidebar {
            position: absolute;
            width: 18px !important;
            right: 10px;
            top: 15px;
        }
    }

    &__routes {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #F3F3F3F3;
        max-height: 270px;
        min-height: 200px;
        overflow-y: scroll;

        &__admin {
            max-height: unset;
        }

        &__item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            cursor: pointer;
            width: 100%;
            border-left: 5px solid #1A1A1A;
            transition: 400ms;
            padding-left: 5px;
            position: relative;

            &__dropdown {
                max-height: 0px;
                overflow: hidden;
                position: absolute;
                background: #1A1A1A;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                z-index: 99999;
                top: 30px;
                left: 0;
                border-radius: 5px;
                transition: all 400ms;

                &>p {
                    color: white;
                    cursor: pointer;
                    font-size: 14px;

                    &:hover {
                        color: #CAFF33;
                    }
                }

                &__active {
                    max-height: 300px;
                    border: 2px solid #262626;
                    padding: 20px;
                }
            }

            &>img {
                width: 20px;
            }

            &>p {
                color: white;
                font-size: 15px;
            }

            &:hover {
                border-left: 5px solid #CAFF33;
            }
        }
    }

    &__manager {
        padding: 20px;
        border-bottom: 1px solid #F3F3F3F3;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;

        &>h3 {
            color: white;
            font-size: 15px;
            font-weight: 500;
        }

        &>span {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            &>img {
                width: 20px;
            }

            &>p {
                font-size: 13px;
                color: white;
            }
        }
    }

    &__balance {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        width: 100%;
        border-bottom: 1px solid #F3F3F3F3;

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;

            &>p {
                font-size: 13px;
                color: white;
            }

            &>strong {
                font-size: 17px;
                color: white;
                font-weight: 500;
            }
        }
    }

    &__profile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 20px;
        border-bottom: 1px solid #F3F3F3F3;
        width: 100%;
        cursor: pointer;
        position: relative;

        &__dropdown {
            max-height: 0px;
            overflow: hidden;
            position: absolute;
            background: #1A1A1A;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            right: -130px;
            z-index: 99999;
            border-radius: 5px;
            transition: all 400ms;
            width: 150px;
            top: 50px;

            &>p {
                color: white;
                cursor: pointer;
                font-size: 14px;

                &:hover {
                    color: #CAFF33;
                }
            }

            &__active {
                max-height: 300px;
                border: 2px solid #262626;
                padding: 20px;
            }
        }

        &__left {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;

            &>img {
                width: 30px;
                border-radius: 50%;
            }

            &>p {
                font-size: 14px;
                color: white;
            }
        }

        &__right {
            &>img {
                width: 14px;
            }
        }
    }

    &__language {
        padding: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        border-bottom: 1px solid #F3F3F3F3;

        &>p {
            color: white;
            font-size: 15px;
        }

        &__list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;

            &>img {
                padding-bottom: 5px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1700px) {
    .component__sidebar {
        overflow-y: scroll;
        overflow-x: hidden;
        min-height: 100%;
        max-height: 100vh;
    }
    .component__sidebar__profile__dropdown__active{
        right: 0;
        top: 60px;
    }
}

@media screen and (max-width: 1400px) {

    .component__sidebar__manager,
    .component__sidebar__logo,
    .component__sidebar__routes,
    .component__sidebar__balance,
    .component__sidebar__profile,
    .component__sidebar__language {
        padding: 10px;
    }

    .component__sidebar__routes__item>p,
    .component__sidebar__language>p,
    .component__sidebar__manager>h3 {
        font-size: 14px;
    }

    .component__sidebar__profile__left>p {
        font-size: 13px;
    }

    .component__sidebar__balance__left>strong {
        font-size: 15px;
    }

    .component__sidebar__balance {
        img {
            width: 28px;
        }
    }
}

@media screen and (max-width: 1040px) {
    .component__sidebar__profile__dropdown__active{
        right: 0;
        top: 40px;
    }
}