.component__yesNoModal {
    width: 100%;
    height: 100%;
    background: rgba(18, 18, 18, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;

    &__wrapper {
        width: 563px;
        height: 270px;
        background: #F3F3F3F3;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        position: relative;
        padding: 40px 40px;
        opacity: 0;

        &__close{
            position: absolute;
            top: 22px;
            right: 25px;
            width: 15px;
            height: 15px;
            cursor: pointer;
        }

        &__heading {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            text-align: right;
            letter-spacing: 0.05em;
            color: #13213C;
        }

        &__text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            letter-spacing: 0.05em;
            color: #1A1A1A;
        }
        
        &__errorMessage{
            color: red;
            font-family: 'Inter';
        }

        &__buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 30px;
            width: 100%;

            &__leftBtn {
                width: 152px;
                height: 46px;
                border-radius: 5px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #FFFFFF;
                border: none;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: 400ms;
            }

            &__rightBtn {
                width: 161px;
                height: 46px;
                border-radius: 5px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #FFFFFF;
                border: none;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: 400ms;
            }
        }
    }
}

@media screen and (max-width: 595px){
    .component__yesNoModal__wrapper{
        width: 95% !important;
    }
}