.route__landingPage {
    padding-left: 162px;
    padding-right: 162px;
    padding-top: 53px;

    @media screen and (max-width: 1470px){
        padding-left: 100px;
        padding-right: 100px;
    }

    @media screen and (max-width: 1200px){
        padding-left: 50px;
        padding-right: 50px;
    }

    @media screen and (max-width: 520px){
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }

    &__hero {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 20px;
        padding-top: 100px;
        width: 100%;

        @media screen and (max-width: 850px){
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr;
            height: 500px;
            padding-top: 50px;
        }

        @media screen and (max-width: 520px){
           padding-top: 20px;
        }

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;
            width: 100%;

            &>h1 {
                color: white;
                font-weight: 500;
                font-size: 38px;
                @media screen and (max-width: 1400px){
                    font-size: 32px;
                }
                @media screen and (max-width: 520px){
                    font-size: 24px;
                }
            }

            &>p {
                color: white;
                font-size: 17px;
                @media screen and (max-width: 1400px){
                    font-size: 15px;
                }
            }

            &__button {
                background: #CAFF33;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                cursor: pointer;
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 18px;
                padding-bottom: 18px;
                font-size: 17px;
                @media screen and (max-width: 520px){
                    font-size: 15px;
                }
            }
        }

        &__right {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            width: 100%;

            @media screen and (max-width: 850px){
                &>img{
                    width: 300px;
                }
            }
        }
    }

    &__somethingNew {
        padding-top: 100px;
        padding-bottom: 100px;
                
        @media screen and (max-width: 850px){
            padding-top: 100px;
            padding-bottom: 50px;
        }

        &>h1 {
            font-size: 32px;
            color: #CAFF33;
            font-weight: 500;
            @media screen and (max-width: 1400px){
                font-size: 26px;
            }
            @media screen and (max-width: 520px){
                font-size: 22px;
            }
        }

        &>p {
            font-size: 17px;
            color: white;
            margin-top: 15px;
            @media screen and (max-width: 1470px){
                margin-bottom: 20px;
            }
            @media screen and (max-width: 1400px){
                font-size: 15px;
            }
        }

        &__grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            @media screen and (max-width: 1400px){
                grid-template-columns: 2fr 1fr;
            }
            @media screen and (max-width: 850px){
                grid-template-columns: 1fr;
            }

            &__left {
                display: grid;
                grid-template-columns: 1fr 3fr;
                gap: 20px;
                padding-top: 50px;
                @media screen and (max-width: 520px){
                    grid-template-columns: 1fr;
                    padding-top: 20px;
                }

                &__AR {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;

                    &>h3 {
                        font-size: 70px;
                        color: #CAFF33;
                    }

                    &>p {
                        color: white;
                        font-size: 24px;

                    }
                }

                &__explain {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;

                    &>h3 {
                        color: #CAFF33;
                        font-weight: 500;
                    }

                    &>p {
                        color: white;
                        @media screen and (max-width: 1400px){
                            font-size: 15px;
                        }
                    }
                }
            }

            &__right {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;

                &>img {
                    width: 400px;
                    @media screen and (max-width: 1400px){
                        width: 300px;
                    }
                }
            }
        }
    }

    &__bonuses {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 20px;
        padding-top: 100px;
        padding-bottom: 100px;
        @media screen and (max-width: 850px){
            grid-template-columns: 1fr;
            padding-bottom: 50px;
            padding-top: 50px;
        }

        &__left {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            @media screen and (max-width: 850px){
                order: 1;
            }

            &>img {
                width: 400px;
                @media screen and (max-width: 1400px){
                    width: 300px;
                }
                @media screen and (max-width: 850px){
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;
            @media screen and (max-width: 850px){
                order: 0;
            }

            &>h1 {
                font-size: 32px;
                color: #CAFF33;
                font-weight: 500;
                @media screen and (max-width: 1400px){
                    font-size: 26px;
                }
                @media screen and (max-width: 520px){
                    font-size: 22px;
                }
            }

            &>p {
                font-size: 17px;
                color: white;
                margin-top: 15px;
                @media screen and (max-width: 1400px){
                    font-size: 15px;
                }
            }

            &__button {
                background: #CAFF33;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                cursor: pointer;
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 18px;
                padding-bottom: 18px;
                font-size: 17px;
                margin-left: 0;
                margin-right: auto;
                @media screen and (max-width: 520px){
                    font-size: 15px;
                }
            }
        }
    }

    &__advertisers {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        padding-top: 100px;
        padding-bottom: 100px;
        @media screen and (max-width: 1300px){
            grid-template-columns: 1fr 2fr;
        }
        @media screen and (max-width: 850px){
            grid-template-columns: 1fr;
            padding-top: 50px;
            padding-bottom: 50px;
        }

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;

            &>h1 {
                font-size: 32px;
                color: #CAFF33;
                font-weight: 500;
                @media screen and (max-width: 1400px){
                    font-size: 26px;
                }
                @media screen and (max-width: 520px){
                    font-size: 22px;
                }
            }

            &>p {
                font-size: 17px;
                color: white;
                @media screen and (max-width: 1400px){
                    font-size: 15px;
                }
            }

            &__button{
                background: #CAFF33;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                cursor: pointer;
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 18px;
                padding-bottom: 18px;
                font-size: 17px;
                margin-left: 0;
                margin-right: auto;
                @media screen and (max-width: 520px){
                    font-size: 15px;
                }
            }
        }

        &__right {
            &__grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;

                @media screen and (max-width: 520px){
                    grid-template-columns: 1fr;
                }
                &__item{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 20px;
                    background: #1C1C1C;
                    border: 1px solid #262626;
                    padding: 20px;
                    border-radius: 20px;
                    &>p {
                        font-size: 17px;
                        color: white;
                        @media screen and (max-width: 1400px){
                            font-size: 15px;
                        }
                    }

                    &>strong{
                        font-size: 18px;
                        color: white;
                        @media screen and (max-width: 1400px){
                            font-size: 16px;
                        }
                    }

                    &>img{
                        width: 38px;
                    }
                }
            }
        }
    }

    &__affiliates {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        padding-top: 100px;
        padding-bottom: 100px;
        @media screen and (max-width: 1300px){
            grid-template-columns: 2fr 1fr;
        }
        @media screen and (max-width: 850px){
            grid-template-columns: 1fr;
            padding-top: 50px;
            padding-bottom: 50px;
        }

        &__right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;
            @media screen and (max-width: 850px){
                order: 0;
            }

            &>h1 {
                font-size: 32px;
                color: #CAFF33;
                font-weight: 500;
                @media screen and (max-width: 1400px){
                    font-size: 26px;
                }
                @media screen and (max-width: 520px){
                    font-size: 22px;
                }
            }

            &>p {
                font-size: 17px;
                color: white;
                @media screen and (max-width: 1400px){
                    font-size: 15px;
                }
            }

            &__button{
                background: #CAFF33;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                cursor: pointer;
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 18px;
                padding-bottom: 18px;
                font-size: 17px;
                margin-left: 0;
                margin-right: auto;
                @media screen and (max-width: 520px){
                    font-size: 15px;
                }
            }
        }

        &__left {
            @media screen and (max-width: 850px){
                order: 1;
            }
            &__grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                @media screen and (max-width: 520px){
                    grid-template-columns: 1fr;
                }
                &__item{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 20px;
                    background: #1C1C1C;
                    border: 1px solid #262626;
                    padding: 20px;
                    border-radius: 20px;
                    &>p {
                        font-size: 17px;
                        color: white;
                        @media screen and (max-width: 1400px){
                            font-size: 15px;
                        }
                    }

                    &>strong{
                        font-size: 18px;
                        color: white;
                        @media screen and (max-width: 1400px){
                            font-size: 16px;
                        }
                    }

                    &>img{
                        width: 38px;
                    }
                }
            }
        }
    }

    &__verticals{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        padding-top: 100px;
        padding-bottom: 100px;
        @media screen and (max-width: 850px){
            grid-template-columns: 1fr;
            padding-top: 50px;
            padding-bottom: 50px;
        }

        &__left{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;
            &>h1 {
                font-size: 32px;
                color: #CAFF33;
                font-weight: 500;
                @media screen and (max-width: 1400px){
                    font-size: 26px;
                }
                @media screen and (max-width: 520px){
                    font-size: 22px;
                }
            }

            &>p {
                font-size: 17px;
                color: white;
                @media screen and (max-width: 1400px){
                    font-size: 15px;
                }
            }
        }

        &__right{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            &>img{
                width: 400px;
                @media screen and (max-width: 1400px){
                    width: 300px;
                }
            }
        }
    }

    &__partners{
        padding-bottom: 100px;
        padding-top: 100px;
        @media screen and (max-width: 850px){
            grid-template-columns: 1fr;
            padding-top: 50px;
            padding-bottom: 50px;
        }
        &>h1 {
            font-size: 32px;
            color: #CAFF33;
            font-weight: 500;
            margin-bottom: 30px;
            @media screen and (max-width: 1400px){
                font-size: 26px;
            }
            @media screen and (max-width: 520px){
                font-size: 22px;
            }
        }

        &__grid{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 20px;
            align-items: center;
            background: #1C1C1C;
            padding: 20px;
            border: 1px solid #262626;
            border-radius: 20px;
            @media screen and (max-width: 850px){
                grid-template-columns: 1fr 1fr 1fr;
            }
            @media screen and (max-width: 520px){
                grid-template-columns: 1fr 1fr;
            }

            &>img{
                width: 60%;
                @media screen and (max-width: 850px){
                    width: 50%;
                }
                @media screen and (max-width: 850px){
                    width: 80%;
                }
            }
        }
    }
}