.component__accessDenied{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.671);
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__wrapper{
        width: 350px;
        height: 200px;
        background: #1A1A1A;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        padding: 20px;

        &>p{
            color: #CAFF33;
            text-align: center;
        }
    }
}