.route__affiliateOffers {
    width: 100%;
    height: 100vh;
    background: #F3F3F3F3;
    padding: 20px;
    overflow-y: scroll;

    &__header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }

        &__filters {
            background: white;
            display: flex;
            gap: 20px;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            &__button {
                width: 150px;
                height: 30px;
                padding: 5px;
                cursor: pointer;
                background: #262626;
                border: 1px solid #A0AEC0;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                color: white;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                border-radius: 5px;
                transition: 400ms;

                &>img {
                    width: 20px;
                }

                &:hover {
                    background: rgb(65, 65, 65);
                }
            }

            &__box {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                width: 100%;
                max-height: 0px;
                opacity: 0;
                overflow: hidden;
                grid-column: span 2;
                transition: all 400ms ease-in-out;

                &__active {
                    max-height: 100px;
                    opacity: 1;
                    overflow: unset;
                    margin-top: 10px;
                }

                &__search {
                    width: 250px;
                    height: 30px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    padding-left: 5px;
                    padding-right: 5px;
                    background: transparent;
                    border: 1px solid #A0AEC0;
                    border-radius: 5px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                    &>input {
                        width: 100%;
                        height: 100%;
                        background: transparent;
                        color: #A0AEC0;
                        outline: none;
                        border: none;
                        font-size: 14px;

                        &::placeholder {
                            color: #A0AEC0;
                        }
                    }

                    &>img {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &__offersGrid {
        width: 100%;
        padding-top: 20px;

        &__content {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;

            &__singleOffer {
                width: 100%;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                background: white;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                padding: 30px;
                border-radius: 5px;
                min-height: 100%;

                &__top {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 3fr;
                    gap: 20px;

                    &>img {
                        width: 100%;
                    }

                    &__right {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 20px;

                        &>h1 {
                            font-size: 20px;
                            font-weight: 600;
                            color: #1A1A1A;
                        }

                        &__span {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            align-items: center;
                            gap: 10px;
                            border-bottom: 1px solid #E0E0E0;
                            width: 100%;
                            padding-bottom: 10px;

                            &__id {
                                background: rgba(62, 84, 110, 0.18);
                                color: rgb(62, 84, 110);
                                padding: 4px 8px;
                                border-radius: 20px;
                                text-transform: uppercase;
                                font-size: 12px;
                                font-weight: 600;
                            }

                            &__privacy {
                                background: rgb(35, 150, 127);
                                color: white;
                                padding: 4px 8px;
                                border-radius: 20px;
                                text-transform: uppercase;
                                font-size: 12px;
                                font-weight: 600;
                            }

                            &__category {
                                padding: 4px 8px;
                                border-radius: 20px;
                                text-transform: uppercase;
                                font-size: 12px;
                                font-weight: 600;
                                background: rgb(226, 173, 242);
                                color: black;
                            }
                        }
                    }
                }

                &__middle {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #E0E0E0;
                    padding-bottom: 10px;

                    &__field {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 10px;

                        &>label {
                            font-size: 14px;
                            color: #262626;
                        }

                        &>p {
                            font-size: 16px;
                            color: #1A1A1A;
                            font-weight: 600;
                        }

                        &__country {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 10px;

                            &>p {
                                font-size: 16px;
                                color: #1A1A1A;
                                font-weight: 600;
                            }
                        }
                    }
                }

                &__bottom {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: 20px;
                    align-items: center;
                    padding-top: 20px;
                    margin-top: auto;
                    margin-bottom: 0;

                    &__btn {
                        background: #262626;
                        padding: 5px;
                        width: 100px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        border-radius: 20px;
                        transition: 400ms;

                        &>p {
                            font-weight: 500;
                            font-size: 15px;
                            color: white;
                        }

                        &:hover {
                            background: rgb(65, 65, 65);
                        }
                    }

                    &__model {
                        background: rgba(62, 84, 110, 0.18);
                        color: rgb(62, 84, 110);
                        padding: 4px 8px;
                        border-radius: 20px;
                        text-transform: uppercase;
                        font-size: 15px;
                        font-weight: 600;
                        margin-left: auto;
                        margin-right: 0;
                    }
                }
            }
        }

        &__pagination {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-top: 20px;

            &__button {
                background: #262626;
                color: white;
                padding: 5px;
                width: 200px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 5px;
                font-size: 17px;
                transition: 400ms;

                &:hover {
                    background: rgb(65, 65, 65);
                }
            }
        }
    }
}

@media screen and (max-width: 1816px) {
    .route__affiliateOffers__offersGrid__content__singleOffer {
        padding: 20px;
    }
}

@media screen and (max-width: 1740px) {
    .route__affiliateOffers__offersGrid__content__singleOffer__top {
        gap: 10px;
    }

    .route__affiliateOffers__offersGrid__content {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 1480px) {
    .route__affiliateOffers__header__filters__box {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;

        .component__simpleDropdown,
        .route__affiliateOffers__header__filters__box__search {
            width: 100% !important;
        }

        .route__affiliateOffers__header__filters__box__search {
            order: 99;
            grid-column: span 2;
        }
    }
}

@media screen and (max-width: 1240px) {
    .route__affiliateOffers__offersGrid__content__singleOffer__top__right>h1 {
        font-size: 18px;
    }

    .route__affiliateOffers__offersGrid__content__singleOffer__top__right__span__category,
    .route__affiliateOffers__offersGrid__content__singleOffer__top__right__span__privacy,
    .route__affiliateOffers__offersGrid__content__singleOffer__top__right__span__id {
        font-size: 10px;
    }
}

@media screen and (max-width: 1160px) {
    .route__affiliateOffers{
        padding: 10px;
    }
    .route__affiliateOffers__offersGrid__content{
        gap: 10px;
    }
    .route__affiliateOffers__offersGrid{
        padding-top: 10px;
    }
}

@media screen and (max-width: 1087px) {
    .route__affiliateOffers__offersGrid__content{
        grid-template-columns: 1fr;
    }
    .route__affiliateOffers__header > h1, .route__affiliateOffers__offersGrid__content__singleOffer__top__right > h1{
        font-size: 16px;
    }

    .route__affiliateOffers__offersGrid__content__singleOffer__bottom__btn > p, .route__affiliateOffers__offersGrid__content__singleOffer__bottom__model{
        font-size: 14px;
    }

    .route__affiliateOffers__offersGrid__content__singleOffer__middle__field > p, .route__affiliateOffers__offersGrid__content__singleOffer__middle__field__country > p{
        font-size: 15px;
    }

    .route__affiliateOffers__offersGrid__content__singleOffer__middle__field > label{
        font-size: 13px;
    }
    .route__affiliateOffers{
        height: 100%;
    }
}

@media screen and (max-width: 600px) {
    .route__affiliateOffers__header__filters__box{
        grid-template-columns: 1fr 1fr;
    }
    .route__affiliateOffers__header__filters__box__active{
        max-height: 300px;
    }
}

@media screen and (max-width: 440px) {
    .route__affiliateOffers__offersGrid__content__singleOffer{
        padding: 15px;
    }
    .route__affiliateOffers__offersGrid__content__singleOffer__top__right__span{
        gap: 5px;
    }
    .route__affiliateOffers__offersGrid__content__singleOffer__top{
        gap: 5px;
    }
}

@media screen and (max-width: 423px){
    .route__affiliateOffers__offersGrid__content__singleOffer__top__right__span{
        display: grid;
        grid-template-columns: 1fr 1fr;
        .route__affiliateOffers__offersGrid__content__singleOffer__top__right__span__category{
            grid-column: span 2;
        }
    }
    .route__affiliateOffers__offersGrid__content__singleOffer__top{
        grid-template-columns: 1.5fr 3fr;
    }
}