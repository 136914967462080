.calendar-wrapper{
    position: relative;
    width: 200px;
}

.calendar {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    z-index: 1000;
  
    .calendar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
  
      button {
        width: fit-content;
        background-color: #333;
        color: #fff;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 4px;
        font-size: 20px;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #000;
        }
      }
  
      span {
        font-weight: bold;
        font-size: 16px;
        color: #333;
      }
    }
  
    .calendar-days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 5px;
  
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        background-color: #f9f9f9;
        color: #333;
        font-size: 14px;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #cccccc;
        }
  
        &.start-date {
          background-color: #000;
          color: red;
        }
  
        &.end-date {
          background-color: #333;
          color: red;
        }
  
        &.in-range {
          background-color: #e0e0e0;
        }
      }
    }
  }
  
  button {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-left: 5px;
    padding-right: 5px;
    background: transparent;
    border: 1px solid #A0AEC0;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    color: #A0AEC0;
    font-size: 14px;
    cursor: pointer;
    padding-left: 12px;
  }
  