.route__offerPage {
    width: 100%;
    height: 100vh;
    background: #F3F3F3F3;
    padding: 20px;
    overflow-y: scroll;

    &__createStream {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        &__button {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding: 10px;
            background: #262626;
            width: fit-content;
            cursor: pointer;
            border-radius: 5px;
            transition: 400ms;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

            &>img {
                width: 24px;
            }

            &>p {
                color: white;
                font-size: 17px;
            }

            &:hover {
                background: rgb(65, 65, 65);
            }
        }
    }

    &__mainInfo {
        display: grid;
        grid-template-columns: auto 1fr 2fr;
        gap: 20px;
        width: 100%;
        background: white;
        margin-top: 20px;
        padding: 20px;
        height: fit-content;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        max-height: 350px;

        &>img {
            width: 300px;
            height: 300px;
        }

        &__middle {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;

            &>h1 {
                font-size: 20px;
                font-weight: 600;
                color: #1A1A1A;
            }

            &__ipc {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                width: 100%;

                &__id {
                    background: rgba(62, 84, 110, 0.18);
                    color: rgb(62, 84, 110);
                    padding: 4px 8px;
                    border-radius: 20px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 600;
                }

                &__privacy {
                    background: rgb(35, 150, 127);
                    color: white;
                    padding: 4px 8px;
                    border-radius: 20px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 600;
                }

                &__category {
                    padding: 4px 8px;
                    border-radius: 20px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 600;
                    background: rgb(226, 173, 242);
                    color: black;
                }
            }

            &__geo {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;

                &>p {
                    font-weight: 600;
                    font-size: 14px;
                }

                &__flag {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                }
            }
        }

        &__right {
            width: 100%;

            &>h1 {
                font-size: 20px;
                color: #1A1A1A;
                padding-bottom: 3px;
                font-weight: 600;
            }

            &__table {
                width: 100%;
                padding-top: 20px;

                &__mobile{
                    &__headers{
                        display: grid !important;
                        grid-template-columns: 1fr !important;
                        border-bottom: none !important;
                        
                        &>p{
                            color: #1A1A1A;
                            font-size: 15px;
                            border-right: none !important;
                            padding-left: 3px;
                            border-bottom: 1px solid gray;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: 5px;
                            padding-top: 5px;

                            &>b{
                                font-weight: 600;
                            }
                        }
                    }
                }

                &__headers {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                    border-bottom: 1px solid gray;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    border-top: 1px solid gray;

                    &>p {
                        color: #1A1A1A;
                        font-size: 15px;
                        border-right: 1px solid gray;
                        padding-left: 3px;

                        &:last-child {
                            border-right: none;
                        }
                    }
                }

                &__content {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                    border-bottom: 1px solid gray;
                    padding-top: 3px;
                    padding-bottom: 3px;

                    &>p {
                        color: #1A1A1A;
                        font-size: 17px;
                        border-right: 1px solid gray;
                        padding-left: 3px;
                        font-weight: 600;

                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }
        }
    }

    &__description{
        background: white;
        margin-top: 20px;
        padding: 20px;
        height: fit-content;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        width: 100%;

        &>h1{
            font-size: 20px;
            color: #1A1A1A;
            font-weight: 600;
        }

        &>p{
            margin-top: 20px;
            font-size: 15px;
            color: #1A1A1A;
        }
    }
}

@media screen and (max-width: 1591px){
    .route__offerPage__mainInfo__middle__ipc, .route__offerPage__mainInfo{
        gap: 10px;
    }
}

@media screen and (max-width: 1560px){
    .route__offerPage__mainInfo{
        grid-template-columns: 0.5fr 1fr;
        max-height: fit-content;
    }
    .route__offerPage__mainInfo__right{
        grid-column: span 2;
    }
    .route__offerPage__mainInfo > img{
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 1591px){
    .route__offerPage{
        padding: 10px;
    }
    .route__offerPage__mainInfo__middle > h1, .route__offerPage__mainInfo__right > h1, .route__offerPage__description > h1{
        font-size: 18px;
    }
    .route__offerPage__createStream__button > p, .route__offerPage__mainInfo__right__table__content > p{
        font-size: 15px;
    }
    .route__offerPage__mainInfo__right__table__headers > p{
        font-size: 13px;
    }
    .route__offerPage__description > p{
        font-size: 14px;
    }
}

@media screen and (max-width: 1050px){
    .route__offerPage{
        height: 100%;
    }
}

@media screen and (max-width: 670px){
    .route__offerPage__mainInfo{
        grid-template-columns: 0.5fr 1fr;
    }
    .route__offerPage__mainInfo > img{
        width: 200px;
        height: 200px;
    }
}

@media screen and (max-width: 572px){
    .route__offerPage__mainInfo{
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .route__offerPage__mainInfo > img{
        width: 300px;
        height: 300px;
    }
    .route__offerPage__mainInfo__right{
        grid-column: span 1;
    }
    .route__offerPage__mainInfo__right__table__headers > p{
        font-size: 15px;
    }
    .route__offerPage__mainInfo__right__table{
        padding-top: 10px;
    }
    .route__offerPage__mainInfo__middle__geo{
        flex-direction: row;
    }
    .route__offerPage__mainInfo__middle__geo > p{
        font-size: 15px;
    }

    .route__offerPage__createStream__button > img{
        width: 18px;
    }
}