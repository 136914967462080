.route__promoteInstructions {
    width: 100%;
    height: 100%;
    background: #F3F3F3F3;
    padding: 20px;
    max-height: 100vh;
    overflow-y: scroll;

    &__body {
        width: 100%;

        &>h1 {
            font-size: 20px;
            color: #1A1A1A;
            margin-bottom: 10px;
        }

        &>p {
            font-size: 15px;
            margin-bottom: 10px;
        }

        &>strong {
            background: white;
            width: 100%;
            display: block;
            padding: 10px;
            border-radius: 5px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;

            &>p {
                font-weight: 400;
            }
        }

        &__grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            padding-top: 20px;

            &__left {
                &>h3 {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                &__code {
                    width: 100%;

                    &>pre {
                        background-color: #262626;
                        color: #CAFF33;
                        font-family: 'Courier New', Courier, monospace;
                        font-size: 14px;
                        padding: 16px;
                        border-radius: 5px;
                        overflow-x: auto;
                        line-height: 1.5;
                        white-space: pre-wrap;

                        &>code {
                            display: block;
                        }
                    }
                }
            }

            &__right {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                &>h3 {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                &__code {
                    width: 100%;

                    &>pre {
                        background-color: #262626;
                        color: #CAFF33;
                        font-family: 'Courier New', Courier, monospace;
                        font-size: 14px;
                        padding: 16px;
                        border-radius: 5px;
                        overflow-x: auto;
                        line-height: 1.5;
                        white-space: pre-wrap;

                        &>code {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1708px){
    .route__promoteInstructions__body__grid{
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 1300px){
    .route__promoteInstructions__body > h1{
        font-size: 18px;
    }
    .route__promoteInstructions__body > strong, .route__promoteInstructions__body > p{
        font-size: 14px;
    }
    .route__promoteInstructions__body__grid__left > h3, .route__promoteInstructions__body__grid__right > h3{
        font-size: 16px;
    }
    .route__promoteInstructions__body__grid__left__code > pre > code{
        font-size: 13px;
    }
}

@media screen and (max-width: 1160px){
    .route__promoteInstructions{
        padding: 10px;
    }
}

@media screen and (max-width: 1050px){
    .route__promoteInstructions{
        max-height: 100%;
    }
}

@media screen and (max-width: 432px){
    .route__promoteInstructions__body > strong{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
    }
}