.route__adminLeads {
    width: 100%;
    height: 100%;
    background: #F3F3F3F3;
    padding: 20px;
    overflow-y: scroll;
    max-height: 100vh;

    &__header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }

        &__filters {
            background: white;
            display: flex;
            gap: 20px;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            &__button {
                width: 150px;
                height: 30px;
                padding: 5px;
                cursor: pointer;
                background: #262626;
                border: 1px solid #A0AEC0;
                color: white;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                border-radius: 5px;
                transition: 400ms;

                &>img {
                    width: 20px;
                }

                &:hover {
                    background: rgb(65, 65, 65);
                }
            }

            &__box {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                width: 100%;
                max-height: 0px;
                opacity: 0;
                overflow: hidden;
                grid-column: span 2;
                transition: all 400ms ease-in-out;

                &__active {
                    max-height: 100px;
                    opacity: 1;
                    overflow: unset;
                    margin-top: 10px;
                }

                &__search {
                    width: 250px;
                    height: 30px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    padding-left: 5px;
                    padding-right: 5px;
                    background: transparent;
                    border: 1px solid #A0AEC0;
                    border-radius: 5px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                    &>input {
                        width: 100%;
                        height: 100%;
                        background: transparent;
                        color: #A0AEC0;
                        outline: none;
                        border: none;
                        font-size: 14px;

                        &::placeholder {
                            color: #A0AEC0;
                        }
                    }

                    &>img {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &__table {
        margin-top: 20px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &__headers {
            width: 100%;
            display: grid;
            grid-template-columns: 0.5fr 0.5fr 1fr 1fr 0.5fr 2fr 1fr 1fr 0.2fr;
            padding: 10px;
            background: white;

            &>p {
                color: #1A1A1A;
                font-size: 14px;
                font-weight: 600;
            }
        }

        &__content {
            width: 100%;
            max-height: 100%;
            background: #FFFFFF;

            &__row {
                width: 100%;
                display: grid;
                grid-template-columns: 0.5fr 0.5fr 1fr 1fr 0.5fr 2fr 1fr 1fr 0.2fr;
                align-items: center;
                padding: 10px;
                height: 64px;
                cursor: pointer;

                &__arrow {
                    width: 16px;
                    transition: all 400ms;

                    &__active {
                        transform: rotate(180deg);
                    }
                }

                &__additionalInfo {
                    max-height: 0px;
                    overflow: hidden;
                    transition: all 0.4s ease-in-out;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    gap: 10px;

                    &>p {
                        color: #1A1A1A;
                        font-size: 13px;
                        background: rgba(184, 184, 184, 0.479);
                        padding: 5px;
                        width: 100%;

                        &>b {
                            font-weight: 600;
                        }
                    }

                    &__active {
                        max-height: 300px;
                        padding: 10px;
                    }
                }

                &__status {
                    width: 65%;
                    height: 30px;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px !important;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    font-weight: 500;
                }

                &__country {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                }

                &>p {
                    color: #1A1A1A;
                    font-size: 14px;
                }

                &__actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    &>img {
                        cursor: pointer;
                    }
                }
            }

            &__pagination {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 20px;

                &__btn {
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: fit-content;
                    border-radius: 5px;
                    cursor: pointer;
                    background: #262626;
                    padding: 10px;
                    font-size: 15px;
                    color: white;
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {
    .route__adminLeads__table__headers {
        grid-template-columns: 0.5fr 0.5fr 1fr 1fr 0.5fr 1fr 1fr 1fr 0.2fr;
    }

    .route__adminLeads__table__content__row {
        grid-template-columns: 0.5fr 0.5fr 1fr 1fr 0.5fr 1fr 1fr 1fr 0.2fr;
    }
}

@media screen and (max-width: 1400px) {
    .route__adminLeads__table__headers {
        grid-template-columns: 0.3fr 0.5fr 1fr 1fr 0.5fr 1fr 1fr 1fr 0.2fr;
    }

    .route__adminLeads__table__content__row {
        grid-template-columns: 0.3fr 0.5fr 1fr 1fr 0.5fr 1fr 1fr 1fr 0.2fr;
    }

    .route__adminLeads__header__filters__box {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;

        &__active{
            max-height: 300px;
        }

        .calendar-wrapper {
            width: 100% !important;
        }

        .component__simpleDropdown {
            width: 100% !important;
        }
    }

    .route__adminLeads__header__filters__box__search {
        width: 100%;
        order: 99;
        grid-column: span 3;
    }
}

@media screen and (max-width: 1350px) {
    .route__adminLeads__table {
        max-width: calc(100vw - 240px);
        overflow-x: scroll;
        white-space: nowrap;

        &::-webkit-scrollbar {
            height: 6px;
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: gray;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgb(185, 184, 184);;
        }
    }

    .route__adminLeads__table__headers,
    .route__adminLeads__table__content {
        width: 1700px;
    }
}

@media screen and (max-width: 1160px) {
    .route__adminLeads{
        padding: 10px;
    }
    .route__adminLeads__table {
        max-width: calc(100vw - 220px);
    }

    .route__adminLeads__table__content__pagination__btn{
        margin-left: 0;
    }
}

@media screen and (max-width: 1040px) {
    .route__adminLeads{
        max-height: 100%;
        padding: 20px;
    }
    .route__adminLeads__table {
        max-width: calc(100vw - 40px);
        overflow-x: scroll;
        white-space: nowrap;
    }

    .route__adminLeads__table__headers,
    .route__adminLeads__table__content {
        width: 1500px;
    }
    .route__adminLeads__header > h1{
        font-size: 16px;
    }
}

@media screen and (max-width: 460px) {
    .route__adminLeads__header__filters{
        gap: 10px;
    }
    .route__adminLeads__header__filters__button{
        font-size: 13px;
        width: 120px;
    }
    .route__adminLeads{
        padding: 10px;
    }
    .route__adminLeads__table{
        max-width: calc(100vw - 20px);
    }
    .route__adminLeads__header__filters__box{
        grid-template-columns: 1fr 1fr;
    }
    .route__adminLeads__header__filters__box__search{
        grid-column: span 2;
    }
}