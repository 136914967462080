.route__documentation{
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    background: #F3F3F3F3;

    &__wrap{
        &>h1{
            font-size: 20px;
            font-weight: 600;
        }

        &__box{
            background: white;
            padding: 20px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;

            &>h3{
                font-size: 17px;
                font-weight: 600;
            }

            &>strong{
                font-size: 15px;
                font-weight: 600;
            }

            &__url{
                background: rgba(128, 128, 128, 0.329);
                padding: 5px;
                border-radius: 5px;
                
            }

            &__code {
                width: 100%;

                &>pre {
                    background-color: #262626;
                    color: #CAFF33;
                    font-family: 'Courier New', Courier, monospace;
                    font-size: 14px;
                    padding: 16px;
                    border-radius: 5px;
                    overflow-x: auto;
                    line-height: 1.5;
                    white-space: pre-wrap;

                    &>code {
                        display: block;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1160px){
    .route__documentation{
        padding: 10px;
    }
}

@media screen and (max-width: 700px){
    .route__documentation__wrap > h1{
        font-size: 18px;
    }
    .route__documentation{
        max-height: 100%;
    }
    .route__documentation__wrap__box{
        padding: 10px;
    }
}

@media screen and (max-width: 422px){
    .route__documentation__wrap__box{
        max-width: 350px;
    }
}