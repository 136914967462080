.route__news{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #F3F3F3F3;
    overflow-y: scroll;
    padding: 20px;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }
        
        &__button{
            width: 150px;
            height: 30px;
            padding: 5px;
            cursor: pointer;
            background: #262626;
            border: 1px solid #A0AEC0;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            border-radius: 5px;
            transition: 400ms;

            &:hover {
                background: rgb(65, 65, 65);
            }
        }
    }

    &__body{
        width: 100%;
        padding-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;

        &__post{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            background: white;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            padding: 30px;
            cursor: pointer;
            position: relative;
            max-height: 170px;
            min-height: 170px;

            &>i{
                font-size: 14px;
            }

            &>span{
                position: absolute;
                top: 35px;
                right: 0;
                background: rgb(197, 92, 92);
                transform: rotate(50deg);
                padding: 4px 8px;
                border-radius: 20px;
                text-transform: uppercase;
                color: white;
                width: 80px;
                text-align: center;
                font-size: 15px;

            }

            &>h1{
                font-size: 18px;
                font-weight: 600;
            }

            &>small{
                padding: 4px 8px;
                border-radius: 20px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                background: rgb(226, 173, 242);
                color: black;
            }
            
            &>p{
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 1744px){
    .route__news__body{
        grid-template-columns: 1fr 1fr !important;
    }
}

@media screen and (max-width: 1160px){
    .route__news{
        padding: 10px !important;
    }
}

@media screen and (max-width: 1040px){
    .route__news__header > h1{
        font-size: 16px !important;
    }
    .route__news__body__post > h1{
        font-size: 16px !important;
    }
}

@media screen and (max-width: 800px){
    .route__news__body{
        grid-template-columns: 1fr !important;
        gap: 10px !important;
    }
    .route__news__body__post{
        padding: 20px !important;
    }
}