.route__adminDashboard {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    padding: 20px;
    overflow-y: scroll;
    background: #F3F3F3F3;

    &__kpis {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;

        &__kpi {
            width: 100%;
            height: fit-content;
            background: white;
            padding: 20px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            border-radius: 5px;

            &:last-child{
                grid-column: span 3;
            }

            &__top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid gray;
                padding-bottom: 10px;


                &__left {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    &>h1 {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }

                &__change {
                    &__neutral {
                        width: 70px;
                        height: 30px;
                        border-radius: 20px;
                        background: #e6f1f8;
                        border: 2px solid #c1d9e8;
                        color: #4a6d8c;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        font-size: 15px;
                    }

                    &__positive {
                        width: 70px;
                        height: 30px;
                        border-radius: 20px;
                        background: #e6f7e6;
                        border: 2px solid #c1e6c1;
                        color: #4a7a4f;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        font-size: 15px;
                    }

                    &__negative {
                        width: 70px;
                        height: 30px;
                        border-radius: 20px;
                        background: #f9e6e6;
                        border: 2px solid #d1a1a1;
                        color: #7a3d3d;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        font-size: 15px;
                    }
                }
            }

            &__bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                width: 100%;

                &__left {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    width: 100%;

                    &>label {
                        font-size: 13px;
                        text-transform: uppercase;
                    }

                    &>p {
                        font-weight: 500;
                        font-size: 26px;
                    }
                }

                &__right {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    width: 100%;

                    &>span {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;

                        &>p {
                            font-size: 13px;
                            text-transform: uppercase;
                        }

                        &>strong {
                            font-weight: 500;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    &__bottom {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        padding-top: 20px;

        &__left {
            &>h1 {
                font-size: 20px;
                font-weight: 600;
            }

            &__news {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                max-height: 450px;
                overflow-y: scroll;
                margin-top: 20px;

                &__article {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    background: white;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                    padding: 20px;
                    cursor: pointer;
                    position: relative;
                    height: fit-content;
                    width: 100%;
                    border-radius: 5px;

                    &>i {
                        font-size: 14px;
                    }

                    &>span {
                        position: absolute;
                        top: 35px;
                        right: 0;
                        background: rgb(197, 92, 92);
                        transform: rotate(50deg);
                        padding: 4px 8px;
                        border-radius: 20px;
                        text-transform: uppercase;
                        color: white;
                        width: 80px;
                        text-align: center;
                        font-size: 15px;

                    }

                    &>h1 {
                        font-size: 18px;
                        font-weight: 600;
                    }

                    &>small {
                        padding: 4px 8px;
                        border-radius: 20px;
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 600;
                        background: rgb(226, 173, 242);
                        color: black;
                    }

                    &>p {
                        font-size: 14px;
                    }
                }
            }
        }

        &__right {
            &>h1 {
                font-size: 20px;
                font-weight: 600;
            }

            &__offers {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                max-height: 450px;
                overflow-y: scroll;
                margin-top: 20px;

                &__offer {
                    width: 100%;
                    height: -moz-fit-content;
                    height: fit-content;
                    display: grid;
                    grid-template-columns: 100px auto;
                    gap: 5px;
                    background: white;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                    padding: 30px;
                    border-radius: 5px;
                    cursor: pointer;

                    &>img {
                        width: 100%;
                    }

                    &__info {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 10px;

                        &>h1 {
                            font-size: 18px;
                            font-weight: 600;
                        }

                        &>span {
                            background: rgba(62, 84, 110, 0.18);
                            color: rgb(62, 84, 110);
                            padding: 4px 8px;
                            border-radius: 20px;
                            text-transform: uppercase;
                            font-size: 14px;
                            font-weight: 600;
                        }

                        &>p{
                            background: rgb(35, 150, 127);
                            color: white;
                            padding: 4px 8px;
                            border-radius: 20px;
                            text-transform: uppercase;
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1670px){
    .route__adminDashboard__kpis__kpi__bottom{
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 10px;
        width: 100%;
    }
}

@media screen and (max-width: 1400px){
    .route__adminDashboard__kpis__kpi__bottom__left > p{
        font-size: 23px;
    }
    .route__adminDashboard__kpis__kpi__bottom__right > span > p, .route__adminDashboard__kpis__kpi__bottom__left > label{
        font-size: 11px !important;
    }
    .route__adminDashboard__kpis__kpi__top__left > h1{
        font-size: 16px;
    }
    .route__adminDashboard__kpis__kpi__top__left{
        img{
            width: 26px;
        }
    }
    .route__adminDashboard__kpis__kpi{
        padding: 15px;
    }
    .route__adminDashboard__kpis__kpi__bottom__right > span > strong{
        font-size: 13px;
    }
    .route__adminDashboard__bottom__right__offers__offer{
        padding: 15px;
    }
    .route__adminDashboard__bottom__left__news__article{
        padding: 15px;
    }
    .route__adminDashboard__kpis__kpi__top__change__neutral{
        font-size: 13px;
        height: 25px;
        width: 60px;
    }
    .route__adminDashboard__bottom__right__offers__offer__info > h1, .route__adminDashboard__bottom__left__news__article > h1{
        font-size: 16px;
    }
    .route__adminDashboard__bottom__right > h1, .route__adminDashboard__bottom__left > h1{
        font-size: 18px;
    }
}

@media screen and (max-width: 1220px){
    .route__adminDashboard__kpis__kpi__top__left > h1{
        font-size: 15px;
    }
    .route__adminDashboard__kpis__kpi{
        padding: 10px;
    }
    .route__adminDashboard__kpis__kpi__bottom__left > p{
        font-size: 20px;
    }
    .route__adminDashboard__kpis__kpi__top__left img{
        width: 24px;
    }
    .route__adminDashboard__kpis__kpi__bottom__right > span > p{
        font-size: 10px !important;
    }
    .route__adminDashboard__kpis__kpi__bottom__right > span > strong{
        font-size: 12px;
    }
    .route__adminDashboard__bottom{
        gap: 10px;
    }
    .route__adminDashboard__bottom__right__offers, .route__adminDashboard__bottom__left__news{
        margin-top: 10px;
    }
    .route__adminDashboard__bottom__right > h1, .route__adminDashboard__bottom__left > h1{
        font-size: 16px;
    }
    .route__adminDashboard__bottom__right__offers__offer__info > h1, .route__adminDashboard__bottom__left__news__article > h1{
        font-size: 14px;
    }
    .route__adminDashboard__bottom__right__offers__offer__info > span{
        font-size: 12px;
    }
}

@media screen and (max-width: 1160px) {
    .route__adminDashboard{
        padding: 10px;
    }
    .route__adminDashboard__kpis{
        gap: 10px;
    }
    .route__adminDashboard__bottom{
        padding-top: 15px;
    }
    .route__adminDashboard__kpis__kpi__bottom__left > p{
        font-size: 18px;
    }
}

@media screen and (max-width: 1080px) {
    .route__adminDashboard{
        padding: 10px;
        max-height: 100%;
    }
    .route__adminDashboard__kpis{
        gap: 10px;
    }
    .route__adminDashboard__bottom{
        padding-top: 15px;
    }
}

@media screen and (max-width: 815px) {
    .route__adminDashboard__kpis{
        grid-template-columns: 1fr 1fr;
    }
    .route__adminDashboard__kpis__kpi:last-child{
        grid-column: span 2;
    }
}

@media screen and (max-width: 690px) {
    .route__adminDashboard__bottom{
        grid-template-columns: 1fr ;
    }
}

@media screen and (max-width: 547px) {
    .route__adminDashboard__kpis{
        grid-template-columns: 1fr;
    }
    .route__adminDashboard__kpis__kpi:last-child{
        grid-column: span 1;
    }
}