.customComponents__spinner {
    animation: rotateSquare 1s linear infinite;
    width: 50px;
    height: 24px;
}

.customComponents__spinner__path {
    animation: scaleSquare 1s ease-in-out infinite;
    transform-origin: center;
}

@keyframes rotateSquare {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes scaleSquare {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.7);
    }
}
