.route__news{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #F3F3F3F3;
    overflow-y: scroll;
    padding: 20px;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }
        
        &__button{
            width: 150px;
            height: 30px;
            padding: 5px;
            cursor: pointer;
            background: #262626;
            border: 1px solid #A0AEC0;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            border-radius: 5px;
            transition: 400ms;

            &:hover {
                background: rgb(65, 65, 65);
            }
        }
    }

    &__body{
        width: 100%;
        padding-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;

        &__post{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            background: white;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            padding: 30px;
            cursor: pointer;
            position: relative;
            max-height: 170px;
            min-height: 170px;

            &>i{
                font-size: 14px;
            }

            &>span{
                position: absolute;
                top: 35px;
                right: 0;
                background: rgb(197, 92, 92);
                transform: rotate(50deg);
                padding: 4px 8px;
                border-radius: 20px;
                text-transform: uppercase;
                color: white;
                width: 80px;
                text-align: center;
                font-size: 15px;

            }

            &>h1{
                font-size: 18px;
                font-weight: 600;
            }

            &>small{
                padding: 4px 8px;
                border-radius: 20px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                background: rgb(226, 173, 242);
                color: black;
            }
            
            &>p{
                font-size: 14px;
            }
        }
    }
}

.component__newPost{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.459);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__wrapper{
        width: 800px;
        height: fit-content;
        background: #F3F3F3F3;

        &__header{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: #E0E0E0;
            padding: 20px;
            border-bottom: 1px solid gray;

            &>h1{
                color: #1A1A1A;
                font-size: 20px;
                font-weight: 600;
            }

            &>img{
                width: 18px;
                cursor: pointer;
            }
        }

        &__body{
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 20px;
            gap: 20px;

            &__field{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;

                &>label{
                    font-size: 15px;
                }

                &>input{
                    width: 100%;
                    outline: none;
                    height: 30px;
                    border: none;
                    border-bottom: 2px solid #1A1A1A;
                    background: transparent;
                    color: #1A1A1A;
                }

                &>input[type='checkbox']{
                    width: 20px;
                }

                &>textarea{
                    width: 100%;
                    outline: none;
                    height: 100px;
                    border: none;
                    border-bottom: 2px solid #1A1A1A;
                    background: #E0E0E0;
                    color: #1A1A1A;
                    padding: 5px;
                }

                &:last-child{
                    grid-column: span 2;
                }
            }
        }

        &__bottom{
            width: 100%;
            padding: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            &__button{
                width: 150px;
                height: 30px;
                padding: 5px;
                cursor: pointer;
                background: #262626;
                border: 1px solid #A0AEC0;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                color: white;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                border-radius: 5px;
                transition: 400ms;
    
                &:hover {
                    background: rgb(65, 65, 65);
                }
            }
        }
    }
}