.route__register{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 162px;
    padding-right: 162px;
    padding-top: 53px;
    padding-bottom: 150px;

    @media screen and (max-width: 1470px){
        padding-left: 100px;
        padding-right: 100px;
    }
    @media screen and (max-width: 1200px){
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 50px;
    }

    @media screen and (max-width: 607px){
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    &__container{
        padding: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        background-image: url("../../../../public/images/registerbackground.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        min-height: 928px;
        position: relative;
        @media screen and (max-width: 1570px){
            min-height: fit-content;
        }
        @media screen and (max-width: 1330px){
            padding: 50px;
        }
        @media screen and (max-width: 607px){
            padding: 20px;
        }

        &__abstract{
            position: absolute;
            top: 0;
            right: 0;
            @media screen and (max-width: 1150px){
                width: 200px;
            }
            @media screen and (max-width: 870px){
                width: 150px;
            }
        }

        &>h1{
            font-size: 32px;
            color: #CAFF33;
            font-weight: 500;
            @media screen and (max-width: 1400px){
                font-size: 26px;
            }
            @media screen and (max-width: 607px){
                font-size: 22px;
            }
        }

        &__option{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;
            width: 30%;
            background: #1C1C1C;
            padding: 20px;
            border: 1px solid #262626;
            border-radius: 20px;
            @media screen and (max-width: 1570px){
                width: 50%;
            }

            @media screen and (max-width: 1150px){
                width: 70%;
            }

            @media screen and (max-width: 870px){
                width: 100%;
            }

            @media screen and (max-width: 607px){
                z-index: 2;
            }

            &__button{
                background: #CAFF33;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                cursor: pointer;
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 18px;
                padding-bottom: 18px;
                font-size: 17px;
                margin-left: 0;
                margin-right: auto;
                width: 100%;
                @media screen and (max-width: 607px){
                    font-size: 15px;
                }
            }

            &>p{
                color: white;
                font-size: 17px;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                @media screen and (max-width: 607px){
                    font-size: 14px;
                }
            }
        }

        @keyframes animateSelection {
            0%{
                opacity: 0;
            }
            100%{
                opacity: 100%;
            }
        }

        &__form{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            width: fit-content;
            animation: animateSelection .5s linear;
            @media screen and (max-width: 1330px){
                width: 100%;
            }
            @media screen and (max-width: 500px){
                grid-template-columns: 1fr;
            }

            &>input{
                background: #1A1A1A;
                border: 1px solid #262626;
                border-radius: 20px;
                outline: none;
                color: #59595A;
                width: 498px;
                height: 75px;
                padding: 24px;
                font-size: 18px;

                @media screen and (max-width: 1330px){
                    width: 100%;
                    height: 50px;
                    font-size: 15px;
                }

                &::placeholder{
                    color: #59595A;
                }
            }

            &__dropdownHead{
                width: 498px !important;
                height: 75px !important;
                @media screen and (max-width: 1330px){
                    width: 100% !important;
                    height: 50px !important;
                }

                .component__simpleDropdown__head{
                    height: 75px !important;
                    border-radius: 20px;
                    background: #1A1A1A;
                    border: 1px solid #262626;
                    padding: 24px !important;
                    @media screen and (max-width: 1330px){
                        width: 100% !important;
                        height: 50px !important;
                    }
                    .component__simpleDropdown__head__text{
                        color: #59595A;
                        font-size: 18px;
                        @media screen and (max-width: 600px){
                            font-size: 15px !important;
                        }
                    }
                }

                .component__simpleDropdown__body__active{
                    max-height: 300px !important;
                    overflow-y: scroll;
                    top: 75px !important;
                    padding: 24px !important;
                    @media screen and (max-width: 600px){
                        top: 43px !important;
                    }
                }

                .component__simpleDropdown__body__item{
                    color: #59595A;
                    border-bottom: 1px solid #262626;
                }
            }

            &__agree{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 100px;
                width: fit-content;
                @media screen and (max-width: 1330px){
                    flex-direction: column;
                    gap: 30px;
                    width: 100%;
                }

                &>span{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 15px;

                    &>p{
                        font-size: 14px;
                        color: white;

                        &>a{
                            cursor: pointer;
                            color: #CAFF33;
                        }
                    }
                }

                &__button{
                    background: #CAFF33;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50px;
                    cursor: pointer;
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-top: 18px;
                    padding-bottom: 18px;
                    font-size: 17px;
                    margin-left: 0;
                    margin-right: auto;
                    width: fit-content;
                    @media screen and (max-width: 1330px){
                        font-size: 15px;
                    }
                    @media screen and (max-width: 607px){
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        width: 100%;
                    }
                }
            }
        }

        &__successImg{
            width: 300px;
            @media screen and (max-width: 520px){
                width: 230px;
            }
        }

        &__success{
            animation: animateSelection .5s linear;
            color: white;
            text-align: center;
            font-size: 16px;
        }
    }
}