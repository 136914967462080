.route__adminAffiliates{
    width: 100%;
    height: 100%;
    background: #F3F3F3F3;
    padding: 20px;

    &__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &>h1{
            font-size: 16px;
            color: #1A1A1A;
            font-weight: 500;
        }

        &__filters{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            background: white;

            &__search{
                width: 250px;
                height: 30px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                padding-left: 5px;
                padding-right: 5px;
                background: transparent;
                border: 1px solid #A0AEC0;
                border-radius: 5px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                &>input{
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    color: #A0AEC0;
                    outline: none;
                    border: none;
                    font-size: 14px;

                    &::placeholder{
                        color: #A0AEC0;
                    }
                }

                &>img{
                    cursor: pointer;
                }
            }
        }
    }

    &__table{
        margin-top: 20px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &__headers{
            width: 100%;
            display: grid;
            grid-template-columns: 0.5fr 1fr 1fr 2fr 3fr 1fr 1fr 1fr 1fr;
            padding: 10px;
            background: white;

            &>p{
                color: #1A1A1A;
                font-size: 14px;
                font-weight: 600;
            }
        }

        &__content{
            width: 100%;
            max-height: 600px;
            min-height: 600px;
            background: #FFFFFF;
            overflow-y: scroll;
            &__row{
                width: 100%;
                display: grid;
                grid-template-columns: 0.5fr 1fr 1fr 2fr 3fr 1fr 1fr 1fr 1fr;
                align-items: center;
                padding: 10px;
                height: 64px;

                &>p{
                    color: #1A1A1A;
                    font-size: 14px;
                }

                &__actions{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    &>img{
                        cursor: pointer;
                        width: 20px;
                        height: 22px;
                    }
                }
            }
        }
    }
}

.component__detailsModal{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.459);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__wrapper{
        width: 800px;
        height: 500px;
        background: #F3F3F3F3;

        &__header{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: #E0E0E0;
            padding: 20px;
            border-bottom: 1px solid gray;

            &>h1{
                color: #1A1A1A;
                font-size: 20px;
                font-weight: 600;
            }

            &>img{
                width: 18px;
                cursor: pointer;
            }
        }

        &__body{
            width: 100%;
            height: calc(100% - 65px);
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 20px;
            padding: 20px;

            &__left{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                height: 100%;
                
                &>h1{
                    font-size: 17px;
                    color: #1A1A1A;
                    font-weight: 600;
                }

                &__field{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    width: 100%;
                    background: #E0E0E0;
                    height: 50px;
                    padding: 5px;
                    border-bottom: 2px dashed gray;

                    &>label{
                        font-size: 13px;
                    }

                    &>p{
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }

            &__right{
                border-left: 2px solid gray;
                height: 100%;
                width: 100%;
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;

                &__top{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    width: 100%;

                    &>h1{
                        font-size: 17px;
                        color: #1A1A1A;
                        font-weight: 600;
                    }

                    &__field{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 5px;
                        width: 100%;
                        background: #E0E0E0;
                        height: 50px;
                        padding: 5px;
                        border-bottom: 2px dashed gray;
    
                        &>label{
                            font-size: 13px;
                        }
    
                        &>input{
                            width: 100%;
                            height: 100%;
                            background: transparent;
                            outline: none;
                            border: none;
                            color: #1A1A1A;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }

                &__bottom{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    width: 100%;

                    &>h1{
                        font-size: 17px;
                        color: #1A1A1A;
                        font-weight: 600;
                    }

                    &__switch{
                        width: 65px;
                        height: 35px;
                        background: #E0E0E0;
                        border-radius: 50px;
                        border: 1px solid gray;
                        padding: 2px;

                        &__circle{
                            width: 30px;
                            height: 100%;
                            background: #262626;
                            border-radius: 50%;
                            cursor: pointer;
                            margin-left: 0;
                            margin-right: auto;
                            transition: all 0.4s linear;

                            &__active{
                                background: #1F9254;
                                margin-left: auto;
                                margin-right: 0;
                            }
                        }
                    }

                    &__button{
                        margin-left: auto;
                        margin-right: 0;
                        width: 150px;
                        height: 30px;
                        padding: 5px;
                        cursor: pointer;
                        background: #262626;
                        box-shadow: 0 0 4px gray;
                        color: #F3F3F3F3;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .route__adminAffiliates__table {
        max-width: calc(100vw - 240px);
        overflow-x: scroll;
        white-space: nowrap;

        &::-webkit-scrollbar {
            height: 6px;
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: gray;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgb(185, 184, 184);;
        }
    }

    .route__adminAffiliates__table__headers,
    .route__adminAffiliates__table__content {
        width: 1700px;
    }
}

@media screen and (max-width: 1041px) {
    .route__adminAffiliates{
        padding: 10px;
    }
    .route__adminAffiliates__header__filters{
        gap: 10px;
    }
    .route__adminAffiliates__table {
        max-width: calc(100vw - 20px);
        overflow-x: scroll;
        white-space: nowrap;

        &::-webkit-scrollbar {
            height: 6px;
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: gray;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgb(185, 184, 184);;
        }
    }

    .route__adminAffiliates__table__headers,
    .route__adminAffiliates__table__content {
        width: 1700px;
    }
}

@media screen and (max-width: 840px) {
    .route__adminAffiliates__header{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
    }
    .route__adminAffiliates__header__filters{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        .component__simpleDropdown{
            width: 100%;
        }
    }
    .route__adminAffiliates__header__filters__search{
        order: 99;
        width: 100%;
        grid-column: span 2;
    }
    .component__detailsModal__wrapper{
        width: 95% !important;
    }
}

@media screen and (max-width: 600px) {
    .component__detailsModal__wrapper__body{
        overflow-y: scroll;
    }
    .component__detailsModal__wrapper__body{
        grid-template-columns: 1fr !important;
        padding: 10px !important;
    }
    .component__detailsModal__wrapper__header > h1{
        font-size: 16px !important;
    }
    .component__detailsModal__wrapper__header > img{
        width: 16px !important;
    }
    .component__detailsModal__wrapper__header{
        padding: 10px !important;
    }
    .component__detailsModal__wrapper__body__left > h1, .component__detailsModal__wrapper__body__right__top > h1, .component__detailsModal__wrapper__body__right__bottom > h1{
        font-size: 15px !important;
    }
    .component__detailsModal__wrapper__body__right{
        border-left: none !important;
        padding-left: 0 !important;
        background: rgba(128, 128, 128, 0.384);
        padding: 5px !important;
    }
}