.route__advertiserLeadStatusReport{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #F3F3F3F3;
    overflow-y: scroll;
    padding: 20px;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }
    }

    &__table {
        margin-top: 20px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &__headers {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            gap: 20px;
            padding: 10px;
            background: white;

            &>p {
                color: #1A1A1A;
                font-size: 14px;
                font-weight: 600;
            }
        }

        &__content {
            width: 100%;
            max-height: 100%;
            background: #FFFFFF;

            &__row {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                gap: 20px;
                align-items: center;
                padding: 10px;
                height: 64px;

                &__status {
                    width: fit-content;
                    height: 30px;
                    padding-left: 12px;
                    padding-right: 12px;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px !important;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    font-weight: 500;
                    border: 1px solid #c1e6c1;
                    color: #4a7a4f !important;
                    background: #e6f7e6;
                }

                &__country {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                }

                &>p {
                    color: #1A1A1A;
                    font-size: 14px;
                }

                &__actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    &>img {
                        cursor: pointer;
                    }
                }
            }

            &__pagination {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 20px;

                &__btn {
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: fit-content;
                    border-radius: 5px;
                    cursor: pointer;
                    background: #262626;
                    padding: 10px;
                    font-size: 15px;
                    color: white;
                }
            }
        }
    }
}

@media screen and (max-width: 1160px){
    .route__advertiserLeadStatusReport{
        padding: 10px;
    }
    .route__advertiserLeadStatusReport__header > h1{
        font-size: 16px;
    }
}

@media screen and (max-width: 805px) {
    .route__advertiserLeadStatusReport__table {
        max-width: calc(100vw - 20px);
        overflow-x: scroll;
        white-space: nowrap;

        &::-webkit-scrollbar {
            height: 6px;
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: gray;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgb(185, 184, 184);;
        }
    }

    .route__advertiserLeadStatusReport__table__headers,
    .route__advertiserLeadStatusReport__table__content {
        width: 1000px;
    }
}