.route__adminStatistics {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    background: #F3F3F3F3;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        height: 50px;

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }

        &__filters {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            gap: 20px;
    
            &__dropdown {
                flex-direction: column;
                justify-content: flex-end;
                position: relative;
    
                &>p{
                    width: 300px;
                    height: 30px;
                    padding-right: 5px;
                    background: transparent;
                    border: 1px solid #A0AEC0;
                    border-radius: 5px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                    color: #A0AEC0;
                    font-size: 14px;
                    padding-left: 12px;
                    outline: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                }
    
                &>input {
                    width: 300px;
                    height: 30px;
                    padding-right: 5px;
                    background: transparent;
                    border: 1px solid #A0AEC0;
                    border-radius: 5px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                    color: #A0AEC0;
                    font-size: 14px;
                    padding-left: 12px;
                    outline: none;
                }
    
                &__body {
                    max-height: 0px;
                    overflow: hidden;
                    transition: all 400ms;
                    background: white;
                    position: absolute;
                    width: 300px;
                    top: 30px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    width: 300px;
                    z-index: 1000;
                    border-radius: 0 0 8px 8px;
    
                    &__active {
                        max-height: 500px;
                        padding: 15px;
                        border: 1px solid #ddd;
                    }
    
                    &__item {
                        cursor: pointer;
                        border-bottom: 1px solid gray;
                        padding: 5px;
                        transition: 400ms;
    
                        &>p {
                            font-size: 14px;
                        }
    
                        &:hover {
                            background: rgba(184, 184, 184, 0.479);
                        }
                    }
                }
            }
        }
    }

    &__table {
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &__headers {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            padding: 5px;
            padding-top: 10px;
            padding-bottom: 10px;
            background: white;

            &>p {
                color: #1A1A1A;
                font-size: 13px;
                font-weight: 600;
                width: 90px;
            }
        }

        &__content {
            width: 100%;
            max-height: 100%;
            background: #FFFFFF;

            &__row {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                align-items: center;
                padding: 5px;
                height: 35px;

                &>p {
                    color: #1A1A1A;
                    font-size: 13px;
                    width: 90px;
                }
            }
        }
    }
}

.ls___chart{
    max-width: 100% !important;
    max-height: 400px !important;
}

@media screen and (max-width: 1900px){
    .route__adminStatistics__table__headers > p{
        font-size: 11px;
    }
}

@media screen and (max-width: 1810px){
    .route__adminStatistics__table{
        max-width: calc(100vw - 270px);
        overflow-x: scroll;
        white-space: nowrap;

        &::-webkit-scrollbar {
            height: 6px;
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: gray;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgb(185, 184, 184);;
        }
    }
    .route__adminStatistics__table__headers, .route__adminStatistics__table__content{
        width: 1800px;
    }
    .route__adminStatistics__table__headers > p{
        font-size: 13px;
    }
    .ls___chart{
        max-width: calc(100vw - 270px) !important;
        max-height: 400px !important;
    }
}

@media screen and (max-width: 1300px){
    .route__adminStatistics__header > h1{
        font-size: 16px;
    }
}

@media screen and (max-width: 1160px){
    .route__adminStatistics{
        padding: 10px;
    }

    .ls___chart{
        max-width: calc(100vw - 220px) !important;
        max-height: 400px !important;
    }
    .route__adminStatistics__table{
        max-width: calc(100vw - 220px);
    }
}

@media screen and (max-width: 1040px){

    .ls___chart{
        max-width: calc(100vw - 20px) !important;
        max-height: 400px !important;
    }
    .route__adminStatistics__table{
        max-width: calc(100vw - 20px);
    }
}

@media screen and (max-width: 660px){
    .route__adminStatistics__header{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: fit-content;
        gap: 10px;
    }
    .route__adminStatistics__header__filters{
        display: grid;
        grid-template-columns: 1.3fr 1fr;
        gap: 5px;
        padding: 0;
        width: 100%;
        .calendar-wrapper{
            width: 100% !important;
            button{
                font-size: 12px !important;
            }
        }
        .route__adminStatistics__header__filters__dropdown > input{
            width: 100% !important;
            font-size: 12px !important;
        }
    }
    .route__adminStatistics__header__filters__dropdown > p{
        width: 100%;
        font-size: 12px;
    }
    .ls___chart{
       max-height: unset;
    }
    .route__adminStatistics__header__filters__dropdown__body__item > p{
        font-size: 12px;
    }
    .route__adminStatistics__header__filters__dropdown__body__active{
        width: 100%;
        padding: 5px;
    }
}