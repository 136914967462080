.route__read{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #F3F3F3F3;
    overflow-y: scroll;
    padding: 20px;

    &__actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        &__button{
            width: 150px;
            height: 30px;
            padding: 5px;
            cursor: pointer;
            background: #262626;
            border: 1px solid #A0AEC0;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            border-radius: 5px;
            transition: 400ms;

            &:hover {
                background: rgb(65, 65, 65);
            }
        }
    }

    &__article{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        background: white;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        padding: 30px;
        margin-top: 20px;
        min-height: 500px;

        &>small{
            font-size: 15px;
        }

        &>h1{
            font-size: 22px;
            border-bottom: 2px solid #E0E0E0;
            padding-bottom: 3px;
            width: 100%;
        }

        &>p{
            font-size: 15px;
        }
    }
}

@media screen and (max-width: 1420px){
    .route__read__article > h1{
        font-size: 18px;
    }
    .route__read__article > small{
        font-size: 13px;
    }
    .route__read__article > p{
        font-size: 14px;
    }
}

@media screen and (max-width: 1160px){
    .route__read{
        padding: 10px !important;
    }
}

@media screen and (max-width: 500px){
    .route__read__article > h1{
        font-size: 16px;
    }
}