.route__tracking{
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    background: #F3F3F3F3;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }
        
        &__button{
            width: 150px;
            height: 30px;
            padding: 5px;
            cursor: pointer;
            background: #262626;
            border: 1px solid #A0AEC0;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            border-radius: 5px;
            transition: 400ms;

            &>img {
                width: 20px;
            }

            &:hover {
                background: rgb(65, 65, 65);
            }
        }
    }

    &__filters{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        background: white;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        padding: 10px;
        padding-right: 5px;

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }
    }

    &__table {
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &__headers {
            width: 100%;
            display: grid;
            grid-template-columns: 0.5fr 1.5fr 2fr 3fr 1fr 1fr 1fr 0.2fr;
            padding: 10px;
            background: white;

            &>p {
                color: #1A1A1A;
                font-size: 14px;
                font-weight: 600;
            }
        }

        &__content {
            width: 100%;
            max-height: 100%;
            background: #FFFFFF;

            &__row {
                width: 100%;
                display: grid;
                grid-template-columns: 0.5fr 1.5fr 2fr 3fr 1fr 1fr 1fr 0.2fr;
                align-items: center;
                padding: 10px;
                height: 64px;
                cursor: pointer;

                &__arrow {
                    width: 16px;
                    transition: all 400ms;

                    &__active {
                        transform: rotate(180deg);
                    }
                }

                &__additionalInfo {
                    max-height: 0px;
                    overflow: hidden;
                    transition: all 0.4s ease-in-out;
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 10px;

                    &>p {
                        color: #1A1A1A;
                        font-size: 13px;
                        background: rgba(184, 184, 184, 0.479);
                        padding: 5px;
                        width: 100%;

                        &>b {
                            font-weight: 600;
                        }
                    }

                    &__active {
                        max-height: 500px;
                        padding: 10px;
                    }
                }

                &__status {
                    width: 65%;
                    height: 30px;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px !important;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    font-weight: 500;
                }

                &__country {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                }

                &>p {
                    color: #1A1A1A;
                    font-size: 14px;
                }

                &__actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    &>img {
                        cursor: pointer;
                    }
                }
            }

            &__pagination {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 20px;

                &__btn {
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: fit-content;
                    border-radius: 5px;
                    cursor: pointer;
                    background: #262626;
                    padding: 10px;
                    font-size: 15px;
                    color: white;
                }
            }
        }
    }
}

.component__newCampaignModal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    cursor: default;

    &__wrapper{
        width: fit-content;
        height: fit-content;
        background: #F3F3F3F3;

        &__header{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: #E0E0E0;
            padding: 20px;
            border-bottom: 1px solid gray;

            &>h1{
                color: #1A1A1A;
                font-size: 20px;
                font-weight: 600;
            }

            &>img{
                width: 18px;
                cursor: pointer;
            }
        }

        &__body{
            padding: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;

            &__field{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;

                &>label{
                    color: #1A1A1A;
                    font-size: 13px;
                }

                &>input{
                    height: 30px;
                    outline: none;
                    border: none;
                    background: transparent;
                    border-bottom: 2px solid #1A1A1A;
                    padding-left: 5px;
                    width: 200px;
                }

                &>p{
                    width: 100%;
                    height: 30px;
                    outline: none;
                    border: none;
                    background: rgba(184, 184, 184, 0.479);
                    border-bottom: 2px solid #1A1A1A;  
                    color: #1A1A1A;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    font-size: 15px;
                    padding-left: 5px;
                    padding-bottom: 3px;
                }

                &__lpurl{
                    grid-column: span 3;
                    width: 100%;
                    &>input{
                        width: 100%;
                    }
                }

                &__dropdown{
                    flex-direction: column;
                    justify-content: flex-end;
                    position: relative;

                    &__body{
                        max-height: 0px;
                        overflow: hidden;
                        transition: all 400ms;
                        background: white;
                        position: absolute;
                        width: 200px;
                        top: 50px;
                        z-index: 999999999;
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                        &__active{
                            max-height: 500px;
                            padding: 5px;
                        }

                        &__item{
                            cursor: pointer;
                            border-bottom: 1px solid gray;
                            padding: 5px;
                            transition: 400ms;

                            &>p{
                                font-size: 14px;
                            }

                            &:hover{
                                background: rgba(184, 184, 184, 0.479);
                            }
                        }
                    }
                }
            }
        }

        &__bottom{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 20px;
            align-items: center;
            padding: 20px;

            &__button{
                width: 200px;
                height: 30px;
                padding: 5px;
                cursor: pointer;
                background: #262626;
                border: 1px solid #A0AEC0;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                color: white;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                border-radius: 5px;
                transition: 400ms;
    
                &>img {
                    width: 20px;
                }
    
                &:hover {
                    background: rgb(65, 65, 65);
                }
            }
        }
    }
}

.component__urlModal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    cursor: default;

    &__wrapper{
        width: 700px;
        height: fit-content;
        background: #F3F3F3F3;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        &>img{
            width: 18px;
            cursor: pointer;
            margin-left: auto;
            margin-right: 0;
            display: block;
        }

        &>label{
            font-size: 15px;
            color: #1A1A1A;
            font-weight: 600;
        }

        &>p{
            font-size: 13px;
            cursor: text;
            color: rgb(19, 19, 192);
            word-break: break-all;
        }
    }
}

@media screen and (max-width: 1685px){
    .route__tracking__table{
        max-width: calc(100vw - 270px);
        overflow-x: scroll;
        white-space: nowrap;

        &::-webkit-scrollbar {
            height: 6px;
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: gray;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgb(185, 184, 184);;
        }
    }
    .route__tracking__table__headers, .route__tracking__table__content{
        width: 1800px;
    }
}

@media screen and (max-width: 1400px){
    .route__tracking__table{
        max-width: calc(100vw - 250px);
    }
}

@media screen and (max-width: 1300px){
    .route__tracking__header > h1, .route__tracking__filters > h1, .component__newCampaignModal__wrapper__header > h1{
        font-size: 16px;
    }
    .component__newCampaignModal__wrapper__header > img, .component__urlModal__wrapper > img{
        width: 16px;
    }
}

@media screen and (max-width: 1160px){
    .route__tracking{
        padding: 10px;
    }
    .route__tracking__table{
        max-width: calc(100vw - 220px);
    }
}
@media screen and (max-width: 1040px){
    .route__tracking__table{
        max-width: calc(100vw - 20px);
    }
}

@media screen and (max-width: 722px){
    .component__newCampaignModal__wrapper__body{
        grid-template-columns: 1fr 1fr;
    }
    .component__newCampaignModal__wrapper__body__field__lpurl{
        grid-column: span 1;
    }
    .component__newCampaignModal__wrapper__body__field > p, .component__newCampaignModal__wrapper__body__field > input{
        font-size: 14px;
    }
    .component__urlModal__wrapper{
        width: 95%;
    }
    .component__urlModal__wrapper > label{
        font-size: 14px;
    }
    .component__urlModal__wrapper > p{
        font-size: 12px;
    }
}

@media screen and (max-width: 500px){
    .component__newCampaignModal__wrapper__body{
        grid-template-columns: 1fr;
    }
    .component__newCampaignModal__wrapper__body__field, .component__newCampaignModal__wrapper__body__field > input{
        width: 100%;
    }
    .component__newCampaignModal__wrapper{
        width: 95%;
    }
    .component__newCampaignModal__wrapper__body{
        max-height: 400px;
        overflow-y: scroll;
    }
}