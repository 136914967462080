.route__adminPayments{
    width: 100%;
    height: 100%;
    background: #F3F3F3F3;
    padding: 20px;
    overflow-y: scroll;
    max-height: 100vh;

    &__header{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        cursor: pointer;

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }
    }

    &__body{
        display: grid;
        grid-template-columns: 1fr 200px;
        gap: 20px;

        &__table{
            width: 100%;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            margin-top: 20px;
    
            &__headers {
                width: 100%;
                display: grid;
                grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                padding: 10px;
                background: white;
    
                &>p {
                    color: #1A1A1A;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
    
            &__content {
                width: 100%;
                max-height: 100%;
                height: 100%;
                background: #FFFFFF;
    
                &__row {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    align-items: center;
                    padding: 10px;
                    height: 64px;
                    cursor: pointer;

                    &__details{
                        max-height: 0px;
                        overflow: hidden;
                        transition: all 0.4s ease-in-out;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;

                        &__button{
                            width: 150px;
                            height: 20px;
                            background: #1A1A1A;
                            color: white;
                            cursor: pointer;
                            padding: 5px;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            transition: 400ms;
                            font-size: 14px;
                
                            &:hover{
                                background: rgb(65, 65, 65);
                            }
                        }
    
                        &>p{
                            color: #1A1A1A;
                            font-size: 13px;
                        }
    
                        &__active{
                            max-height: 50px;
                            padding: 10px;
                        }
                    }
    
                    &__status {
                        width: 65%;
                        height: 30px;
                        border-radius: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 13px !important;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                        font-weight: 500;
                    }
    
                    &>p {
                        color: #1A1A1A;
                        font-size: 14px;
                    }
                }
    
                &__pagination {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
    
                    &__btn {
                        margin-left: auto;
                        margin-right: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: fit-content;
                        border-radius: 5px;
                        cursor: pointer;
                        background: #262626;
                        padding: 10px;
                        font-size: 15px;
                        color: white;
                    }
                }
            }
        }

        &__kpis{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            margin-top: 20px;

            &__pending{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                background: #fff5e6;
                border: 2px solid #ffd0a6;
                padding: 10px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                &>label{
                    font-size: 14px;
                }

                &__bottom{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    &>p{
                        font-size: 17px;
                    }
                }
            }

            &__approved{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                background: #e6f1f8;
                border: 2px solid #c1d9e8;
                padding: 10px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                &>label{
                    font-size: 14px;
                }

                &__bottom{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    &>p{
                        font-size: 17px;
                    }
                }
            }

            &__rejected{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                background: #f9e6e6;
                border: 2px solid #d1a1a1;
                padding: 10px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                &>label{
                    font-size: 14px;
                }

                &__bottom{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    &>p{
                        font-size: 17px;
                    }
                }
            }

            &__paid{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                background: #e6f7e6;
                border: 2px solid #c1e6c1;
                padding: 10px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                &>label{
                    font-size: 14px;
                }

                &__bottom{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    &>p{
                        font-size: 17px;
                    }
                }
            }
        }
    }
}

.component__changeStatus{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.473);
    cursor: default;

    &__wrapper{
        width: 500px;
        height: fit-content;
        background: #F3F3F3F3;

        &__header{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: #E0E0E0;
            padding: 20px;
            border-bottom: 1px solid gray;

            &>h1{
                color: #1A1A1A;
                font-size: 20px;
                font-weight: 600;
            }

            &>img{
                width: 18px;
                cursor: pointer;
            }
        }

        &__body{
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            width: 100%;

            &>label{
                color: #1A1A1A;
                font-size: 15px;
            }

            &__list{
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                width: 100%;

                &__status{
                    width: 100%;
                    cursor: pointer;
                    padding: 10px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                    text-align: center;
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 1160px){
    .route__adminPayments{
        padding: 10px;
    }
    .route__adminPayments__body{
        gap: 10px;
        grid-template-columns: 1fr;
    }
    .route__adminPayments__body__kpis{
        order: 0;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .route__adminPayments__body__table{
        order: 1;
    }
    .route__adminPayments__settings__wrapper__note > p{
        font-size: 14px;
    }
}

@media screen and (max-width: 1040px){
    .route__adminPayments{
        max-height: 100%;
    }
    .route__adminPayments__body__table__content{
        max-height: 500px;
        overflow-y: scroll;
    }
}

@media screen and (max-width: 815px){
    .route__adminPayments__settings__wrapper__body__field__inputs > input{
        font-size: 13px;
    }
}

@media screen and (max-width: 760px){
    .route__adminPayments__settings__wrapper__body{
        grid-template-columns: 1fr;
    }
    .route__adminPayments__settings__wrapper__body__field:last-child{
        grid-column: span 1;
    }
    .route__adminPayments__main__requestPayment{
        grid-template-columns: 1fr 1fr;
        .component__simpleDropdown{
            width: 100% !important;
        }
    }

    .route__adminPayments__body__table {
        max-width: calc(100vw - 20px);
        overflow-x: scroll;
        white-space: nowrap;

        &::-webkit-scrollbar {
            height: 6px;
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: gray;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgb(185, 184, 184);;
        }
    }

    .route__adminPayments__body__table__headers,
    .route__adminPayments__body__table__content {
        width: 900px;
    }
}

@media screen and (max-width: 570px){
    .component__changeStatus__wrapper{
        width: 95%;
    }
    .component__changeStatus__wrapper__header > h1{
        font-size: 16px;
    }
    .component__changeStatus__wrapper__header > img{
        width: 16px;
    }
    .component__changeStatus__wrapper__body__list__status{
        font-size: 14px;
    }
    .route__adminPayments__settings__wrapper__note__button{
        font-size: 14px;
        margin-left: auto;
        margin-right: 0;
    }
    .route__adminPayments__settings__wrapper__note > p{
        font-size: 13px;
    }
    .route__adminPayments__settings__wrapper__note{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
    }
    .route__adminPayments__header > h1{
        font-size: 16px;
    }
    .route__adminPayments__main__requestPayment__button{
        font-size: 14px;
    }
    .route__adminPayments__body__kpis__pending__bottom > p{
        font-size: 15px;
    }
    .route__adminPayments__body__kpis__paid > label{
        font-size: 13px;
    }
    .route__adminPayments__main__requestPayment{
        gap: 10px;
        grid-template-columns: 1fr;
    }
    .route__adminPayments__body__kpis{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .route__adminPayments__main, .route__adminPayments__main__requestPayment{
        padding-top: 10px;
    }
}