.route__advertiserBlacklist{
    width: 100%;
    height: 100%;
    background: #F3F3F3F3;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 20px;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }
    }

    &__body{
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        width: 100%;

        &>textarea{
            width: 50%;
            height: 500px;
            padding: 10px;
            border-radius: 0px;
            border: none;
            outline: none;
        }

        &__button{
            width: 200px;
            background: #1A1A1A;
            color: white;
            cursor: pointer;
            padding: 5px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            transition: 400ms;

            &:hover{
                background: rgb(65, 65, 65);
            }
        }
    }
}

@media screen and (max-width: 1160px){
    .route__advertiserBlacklist{
        padding: 10px;
    }
    .route__advertiserBlacklist__header > h1 > h1{
        font-size: 16px;
    }
}

@media screen and (max-width: 650px){
    .route__advertiserBlacklist__body > textarea{
        width: 100%;
    }
    .route__advertiserBlacklist__body__button{
        font-size: 14px;
    }
    .route__advertiserBlacklist__body > p{
        font-size: 14px;
    }
}