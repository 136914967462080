.component__urgentNews{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.568);
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__wrapper{
        width: 800px;
        height: 500px;
        background: #F3F3F3F3;
        
        &__header{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: #E0E0E0;
            padding: 20px;
            border-bottom: 1px solid gray;

            &>h1{
                color: #1A1A1A;
                font-size: 20px;
                font-weight: 600;
            }

            &>img{
                width: 18px;
                cursor: pointer;
            }
        }

        &__body{
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;

            &>h1{
                font-size: 22px;
                font-weight: 600;
                border-bottom: 2px solid #E0E0E0;
                padding-bottom: 3px;
                width: 100%;
            }

            &>p{
                font-size: 14px;
            }
        }
    }
}