.component__footer{
    width: 100%;
    padding-left: 162px;
    padding-right: 162px;
    padding-top: 100px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 200px;
    background: #1C1C1C;

    @media screen and (max-width: 1470px){
        padding-left: 100px;
        padding-right: 100px;
    }
    @media screen and (max-width: 1200px){
        padding-left: 50px;
        padding-right: 50px;
    }
    @media screen and (max-width: 1200px){
        gap: 100px;
    }
    @media screen and (max-width: 850px){
        grid-template-columns: 1fr;
    }
    @media screen and (max-width: 520px){
        gap: 50px;
        padding-top: 50px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    &__left{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        &__logo{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            &>img{
                width: 60px;
                @media screen and (max-width: 1200px){
                    width: 40px;
                }
            }

            &>h1 {
                font-size: 24px;
                color: #CAFF33;
                font-weight: 500;
                @media screen and (max-width: 1200px){
                    font-size: 20px;
                }
            }
        }

        &>p{
            font-size: 15px;
            color: white;
        }
    }

    &__middle{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        &>p{
            font-size: 15px;
            color: white;
            cursor: pointer;
        }

        &>h1{
            font-size: 24px;
            color: #CAFF33;
            font-weight: 500;
            @media screen and (max-width: 1200px){
                font-size: 20px;
            }
        }
    }

    &__right{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;
        &>h1{
            font-size: 24px;
            color: #CAFF33;
            font-weight: 500;
            @media screen and (max-width: 1200px){
                font-size: 20px;
            }
        }

        &__social{
            display: flex;
            flex-direction: flex-start;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            gap: 10px;

            &>img{
                width: 36px;
                cursor: pointer;
            }
        }

        &>span{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;

            &>p{
                font-size: 15px;
                color: white;
            }
        }
    }
}