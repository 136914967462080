.component__export{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);

    &__wrapper{
        width: fit-content;
        height: fit-content;
        background: #F3F3F3F3;
        &__header{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: #E0E0E0;
            padding: 20px;
            border-bottom: 1px solid gray;

            &>h1{
                color: #1A1A1A;
                font-size: 20px;
                font-weight: 600;
            }

            &>img{
                width: 18px;
                cursor: pointer;
            }
        }

        &__filters{
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;

            &>input{
                width: 100%;
                height: 30px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                padding-left: 5px;
                padding-right: 5px;
                background: transparent;
                border: 1px solid #A0AEC0;
                border-radius: 5px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                color: #A0AEC0;
                font-size: 14px;
                padding-left: 12px;
                outline: none;

                &::placeholder{
                    color: #A0AEC0;
                }
            }

            &__button{
                width: 100%;
                margin-top: 10px;
                height: 30px;
                padding: 5px;
                cursor: pointer;
                background: #262626;
                border: 1px solid #A0AEC0;
                color: white;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                border-radius: 5px;
                transition: 400ms;

                &>img {
                    width: 20px;
                }

                &:hover {
                    background: rgb(65, 65, 65);
                }
            }
        }
    }
}