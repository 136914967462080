.route__login{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 162px;
    padding-right: 162px;
    padding-top: 53px;
    padding-bottom: 150px;

    @media screen and (max-width: 1470px){
        padding-left: 100px;
        padding-right: 100px;
    }
    @media screen and (max-width: 1200px){
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 50px;
    }
    @media screen and (max-width: 607px){
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    &__wrapper{
        padding: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        background-image: url("../../../../public/images/registerbackground.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        min-height: 928px;
        position: relative;
        @media screen and (max-width: 1570px){
            min-height: fit-content;
        }
        @media screen and (max-width: 1330px){
            padding: 50px;
        }
        @media screen and (max-width: 607px){
            padding: 20px;
        }

        &__abstract{
            position: absolute;
            top: 0;
            right: 0;
            @media screen and (max-width: 1150px){
                width: 200px;
            }
            @media screen and (max-width: 870px){
                width: 150px;
            }
        }
        
        &>h1{
            font-size: 32px;
            color: #CAFF33;
            font-weight: 500;
            @media screen and (max-width: 1400px){
                font-size: 26px;
            }
        }

        &>input{
            background: #1A1A1A;
            border: 1px solid #262626;
            border-radius: 20px;
            outline: none;
            color: #59595A;
            width: 498px;
            height: 75px;
            padding: 24px;
            font-size: 18px;
            z-index: 10;

            @media screen and (max-width: 1330px){
                width: 100%;
                height: 50px;
                font-size: 15px;
            }

            &::placeholder{
                color: #59595A;
            }
        }

        &__bottom{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 100px;
            @media screen and (max-width: 607px){
                gap: 20px;
                width: 100%;
                flex-direction: column;
            }
            &>p{
                font-size: 14px;
                color: white;
                cursor: pointer;
            }
            &__button{
                background: #CAFF33;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                cursor: pointer;
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 18px;
                padding-bottom: 18px;
                font-size: 17px;
                width: fit-content;
                @media screen and (max-width: 1330px){
                    font-size: 15px;
                }
                @media screen and (max-width: 607px){
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    width: 100%;
                }
            }
        }
    }
}