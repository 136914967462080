.route__profile{
    width: 100%;
    height: 100%;
    max-height: 100vh;
    background: #F3F3F3F3;
    overflow-y: scroll;
    padding: 20px;

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

        &>h1 {
            font-size: 18px;
            color: #1A1A1A;
            font-weight: 500;
        }
        
        &__button{
            width: 150px;
            height: 30px;
            padding: 5px;
            cursor: pointer;
            background: #262626;
            border: 1px solid #A0AEC0;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            border-radius: 5px;
            transition: 400ms;

            &>img {
                width: 20px;
            }

            &:hover {
                background: rgb(65, 65, 65);
            }
        }
    }

    &__container{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        padding-top: 20px;

        &__editableInfo{
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            background: white;
            padding: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            &__field{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;

                &>label{
                    font-size: 14px;
                    color: #1A1A1A;
                }

                &>input{
                    width: 100%;
                    padding: 5px;
                    outline: none;
                    border: none;
                    background: transparent;
                    border-bottom: 2px solid #1A1A1A;
                    font-size: 15px;
                }
            }
        }

        &__info{
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            background: white;
            padding: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            &__field{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;

                &>label{
                    font-size: 14px;
                    color: #1A1A1A;
                }

                &>p{
                    width: 100%;
                    padding: 5px;
                    outline: none;
                    border: none;
                    background: transparent;
                    border-bottom: 2px solid #1A1A1A;
                    font-size: 15px;
                }
            }
        }
    }

    &__changePassword{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        margin-top: 20px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        background: white;
        padding: 20px;

        &__top{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &>h2{
                font-size: 17px;
                color: #1A1A1A;
                font-weight: 600;
            }
        }

        &__wrapper{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 200px;
            gap: 20px;

            &__field{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;

                &>label{
                    font-size: 14px;
                    color: #1A1A1A;
                }

                &>input{
                    width: 100%;
                    padding: 5px;
                    outline: none;
                    border: none;
                    background: transparent;
                    border-bottom: 2px solid #1A1A1A;
                    font-size: 15px;
                }
            }

            &__button{
                width: 100%;
                min-height: 100%;
                padding: 10px;
                cursor: pointer;
                background: #262626;
                border: 1px solid #A0AEC0;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                color: white;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
                font-size: 15px;
                border-radius: 5px;
                transition: 400ms;
                text-align: center;
    
                &>img {
                    width: 32px;
                }
    
                &:hover {
                    background: rgb(65, 65, 65);
                }
            }
        }
    }
}

@media screen and (max-width: 1300px){
    .route__profile__container__info__field > p, .route__profile__container__editableInfo__field > input{
        font-size: 14px;
    }
    .route__profile__container__info__field > label, .route__profile__container__editableInfo__field > label{
        font-size: 13px;
    }
}

@media screen and (max-width: 1200px){
    .route__profile{
        padding: 10px;
    }
    .route__profile__header > h1{
        font-size: 16px;
    }
    .route__profile__container{
        grid-template-columns: 1fr;
    }
    .route__profile__changePassword__top > h2{
        font-size: 15px;
    }
    .route__profile__changePassword__wrapper__button{
        font-size: 14px;
        padding: 5px;
    }
}

@media screen and (max-width: 1040px){
    .route__profile{
        max-height: 100%;
    }
}

@media screen and (max-width: 650px){
    .route__profile__container__editableInfo, .route__profile__container__info{
        grid-template-columns: 1fr;
    }
    .route__profile__changePassword__wrapper{
        grid-template-columns: 1fr;
    }
}