.component__header {
    width: 100%;
    padding-top: 50px;
    padding-left: 162px;
    padding-right: 162px;
    position: relative;

    @media screen and (max-width: 1470px) {
        padding-left: 100px;
        padding-right: 100px;
    }

    @media screen and (max-width: 1200px) {
        padding-left: 50px;
        padding-right: 50px;
    }

    @media screen and (max-width: 520px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }

    &__container {
        width: 100%;
        height: 95px;
        background: #1C1C1C;
        border: 1px solid #262626;
        border-radius: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 37px 33px 37px 33px;

        @media screen and (max-width: 520px) {
            padding: 24px 21px 24px 21px;
            height: 68px;
        }

        &__mobile {
            position: absolute;
            background: #262626;
            width: 100%;
            left: 0px;
            top: 0px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding-bottom: 0px;
            max-height: 0px;
            overflow: hidden;
            transition: all .5s ease-in-out;
            z-index: 9999999;

            &__active{
                max-height: 1000px;
                padding: 20px;
                padding-bottom: 100px;
            }

            &__login {
                color: white;
                font-size: 15px;
                padding: 10px;
                border-radius: 50px;

                &:last-child {
                    background: #CAFF33;
                    color: #1C1C1C;
                }
            }

            &__top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &__logo {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    &>img {
                        width: 38px;
                        height: 38px;
                    }

                    &>p {
                        color: white;
                        font-size: 17px;
                    }
                }

                &__x {
                    background: #CAFF33;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;

                    &>img {
                        filter: invert(1);
                    }
                }
            }

            &>p {
                color: white;
                font-size: 15px;
                margin-left: auto;
                margin-right: 0;
            }
        }

        &__left {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            &>img {
                width: 38px;
                height: 38px;
            }

            &>p {
                color: white;
                font-size: 20px;

                @media screen and (max-width: 900px) {
                    font-size: 17px;
                }
            }
        }

        &__middle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 0px;

            @media screen and (max-width: 900px) {
                display: none;
            }

            &>p {
                color: white;
                font-size: 17px;
                cursor: pointer;
                padding: 15px;
                border-radius: 50px;
                transition: 400ms;

                @media screen and (max-width: 1400px) {
                    font-size: 15px;
                }

                &:hover {
                    background: #262626;
                }
            }
        }

        &__right {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            &__hamburger {
                display: none;

                @media screen and (max-width: 900px) {
                    display: block;
                }
            }

            &>p {
                color: white;
                font-size: 17px;
                padding: 15px;
                border-radius: 50px;
                cursor: pointer;

                @media screen and (max-width: 1400px) {
                    font-size: 15px;
                }

                @media screen and (max-width: 900px) {
                    display: none;
                }
            }
            
            &__login {
                background: #CAFF33;
                color: #1C1C1C !important;
                font-size: 17px;
            }
        }
    }
}