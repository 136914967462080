@import "../../scss/mixins.scss";

.component__simpleDropdown {
    width: 220px;
    position: relative;

    &__closeBtn {
        position: absolute !important;
        width: 15px;
        height: 15px;
        z-index: 99;
        top: 50%;
        right: 12px;
        cursor: pointer;
        background-size: 100% 100% !important;
        transform: translateY(-50%);
        @include button-hover-animation(22px);
    }

    &__head {
        height: 28px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
        background: white;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        border-radius: 2px;
        cursor: pointer;
        position: relative;

        &__text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #13213C;
        }
    }

    &__body {
        margin-top: 8px;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        position: absolute;
        width: 100%;
        top: 28px;
        transition: all 0.5s ease-in-out;
        z-index: 99999;
        height: auto;
        max-height: 0px;
        overflow: hidden;

        &__search{
            width: 100%;
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: 6px;
            padding-top: 6px;
            height: 40px;

            &>input{
                width: 100%;
                height: 100%;
                padding-left: 5px;
                outline: none;
                border: none;
                border-bottom: 1px solid gray;
            }
        }

        &__active{
            max-height: 300px !important;
            overflow-y: scroll;
        }

        &__item {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #1C1C1C;
            width: 100%;
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: 6px;
            padding-top: 6px;
            cursor: pointer;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            transition: 400ms;

            &:hover {
                background: rgb(240, 240, 240);
            }
        }
    }
}