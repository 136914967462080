.route__payments{
    width: 100%;
    height: 100%;
    background: #F3F3F3F3;
    padding: 20px;
    overflow-y: scroll;
    max-height: 100vh;

    &__settings{
        width: 100%;
        &__header{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 10px;
            align-items: center;
            background: white;
            padding: 10px;
            width: 100%;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            cursor: pointer;
    
            &>h1 {
                font-size: 18px;
                color: #1A1A1A;
                font-weight: 500;
            }

            &__img{
                width: 16px;
                transition: all 400ms;

                &__active{
                    transform: rotate(180deg);
                }
            }
        }

        &__wrapper{
            max-height: 0px;
            overflow: hidden;
            transition: all 400ms;

            &__active{
                max-height: 1000px;
            }

            &__note{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding-top: 20px;
                padding-bottom: 20px;

                &>p{
                    font-size: 15px;
                }

                &__button{
                    width: 200px;
                    height: 30px;
                    background: #1A1A1A;
                    color: white;
                    cursor: pointer;
                    padding: 5px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    transition: 400ms;
        
                    &:hover{
                        background: rgb(65, 65, 65);
                    }
                }
            }
    
            &__body{
                background: white;
                padding: 20px;
                width: 100%;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;

                &__field{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    background: #E0E0E0;
                    padding: 10px;

                    &:last-child{
                        grid-column: span 2;
                    }

                    &>label{
                        font-size: 14px;
                    }

                    &__inputs{
                        display: grid;
                        grid-template-columns: 1fr 1fr; 
                        gap: 5px;
                        width: 100%;

                        &>p{
                            width: 100%;
                            height: 30px;
                            outline: none;
                            border: none;
                            background: transparent;
                            border-bottom: 1px solid gray;   
                            grid-column: span 2;
                            font-size: 14px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                        }

                        &>input{
                            width: 100%;
                            height: 30px;
                            outline: none;
                            border: none;
                            background: transparent;
                            border-bottom: 1px solid gray;

                            &:last-child{
                                grid-column: span 2;
                            }
                        }
                    }
                }
            }
        }
    }

    &__main{
        padding-top: 20px;
        &__header{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 10px;
            align-items: center;
            background: white;
            padding: 10px;
            width: 100%;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    
            &>h1 {
                font-size: 18px;
                color: #1A1A1A;
                font-weight: 500;
            }
        }

        &__requestPayment{
            width: 100%;
            display: grid;
            grid-template-columns: 0.3fr auto 1fr 0.2fr;
            align-items: center;
            gap: 20px;
            padding-top: 20px;
            padding-bottom: 20px;

            &__amount{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 28px;
                background: white;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                &>p{
                    width: 50px;
                    height: 100%;
                    background: #c4c1c1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 3px;
                    font-size: 15px;
                }

                &>input{
                    width: 100%;
                    height: 100%;
                    outline: none;
                    border: none;
                    padding-left: 5px;
                    color: #1A1A1A;
                    font-size: 15px;
                }
            }

            &>input{
                width: 100%;
                height: 28px;
                outline: none;
                border: none;
                background: white;
                padding-left: 5px;
                font-size: 15px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
            }

            &__button{
                width: 100%;
                height: 28px;
                background: #1A1A1A;
                color: white;
                cursor: pointer;
                padding: 5px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                transition: 400ms;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    
                &:hover{
                    background: rgb(65, 65, 65);
                } 
            }
        }

        &__body{
            display: grid;
            grid-template-columns: 1fr 200px;
            gap: 20px;

            &__table{
                width: 100%;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        
                &__headers {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
                    padding: 10px;
                    background: white;
        
                    &>p {
                        color: #1A1A1A;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
        
                &__content {
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                    background: #FFFFFF;
        
                    &__row {
                        width: 100%;
                        display: grid;
                        grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
                        align-items: center;
                        padding: 10px;
                        height: 64px;
        
                        &__status {
                            width: 65%;
                            height: 30px;
                            border-radius: 20px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            font-size: 13px !important;
                            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                            font-weight: 500;
                        }
        
                        &>p {
                            color: #1A1A1A;
                            font-size: 14px;
                        }
                    }
        
                    &__pagination {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 20px;
        
                        &__btn {
                            margin-left: auto;
                            margin-right: auto;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            width: fit-content;
                            border-radius: 5px;
                            cursor: pointer;
                            background: #262626;
                            padding: 10px;
                            font-size: 15px;
                            color: white;
                        }
                    }
                }
            }

            &__kpis{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;

                &__pending{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    background: #fff5e6;
                    border: 2px solid #ffd0a6;
                    padding: 10px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                    &>label{
                        font-size: 14px;
                    }

                    &__bottom{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;

                        &>p{
                            font-size: 17px;
                        }
                    }
                }

                &__approved{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    background: #e6f1f8;
                    border: 2px solid #c1d9e8;
                    padding: 10px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                    &>label{
                        font-size: 14px;
                    }

                    &__bottom{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;

                        &>p{
                            font-size: 17px;
                        }
                    }
                }

                &__rejected{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    background: #f9e6e6;
                    border: 2px solid #d1a1a1;
                    padding: 10px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                    &>label{
                        font-size: 14px;
                    }

                    &__bottom{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;

                        &>p{
                            font-size: 17px;
                        }
                    }
                }

                &__paid{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    background: #e6f7e6;
                    border: 2px solid #c1e6c1;
                    padding: 10px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);

                    &>label{
                        font-size: 14px;
                    }

                    &__bottom{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;

                        &>p{
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1160px){
    .route__payments{
        padding: 10px;
    }
    .route__payments__main__body{
        gap: 10px;
        grid-template-columns: 1fr;
    }
    .route__payments__main__body__kpis{
        order: 0;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .route__payments__main__body__table{
        order: 1;
    }
    .route__payments__settings__wrapper__note > p{
        font-size: 14px;
    }
}

@media screen and (max-width: 1040px){
    .route__payments{
        max-height: 100%;
    }
    .route__payments__main__body__table__content{
        max-height: 500px;
        overflow-y: scroll;
    }
}

@media screen and (max-width: 815px){
    .route__payments__settings__wrapper__body__field__inputs > input{
        font-size: 13px;
    }
}

@media screen and (max-width: 760px){
    .route__payments__settings__wrapper__body{
        grid-template-columns: 1fr;
    }
    .route__payments__settings__wrapper__body__field:last-child{
        grid-column: span 1;
    }
    .route__payments__main__requestPayment{
        grid-template-columns: 1fr 1fr;
        .component__simpleDropdown{
            width: 100% !important;
        }
    }

    .route__payments__main__body__table {
        max-width: calc(100vw - 20px);
        overflow-x: scroll;
        white-space: nowrap;

        &::-webkit-scrollbar {
            height: 6px;
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            background-color: gray;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgb(185, 184, 184);;
        }
    }

    .route__payments__main__body__table__headers,
    .route__payments__main__body__table__content {
        width: 900px;
    }
}

@media screen and (max-width: 570px){
    .route__payments__settings__wrapper__note__button{
        font-size: 14px;
        margin-left: auto;
        margin-right: 0;
    }
    .route__payments__settings__wrapper__note > p{
        font-size: 13px;
    }
    .route__payments__settings__wrapper__note{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
    }
    .route__payments__settings__header > h1, .route__payments__main__header > h1{
        font-size: 16px;
    }
    .route__payments__main__requestPayment__button{
        font-size: 14px;
    }
    .route__payments__main__body__kpis__pending__bottom > p{
        font-size: 15px;
    }
    .route__payments__main__body__kpis__paid > label{
        font-size: 13px;
    }
    .route__payments__main__requestPayment{
        gap: 10px;
        grid-template-columns: 1fr;
    }
    .route__payments__main__body__kpis{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .route__payments__main, .route__payments__main__requestPayment{
        padding-top: 10px;
    }
}